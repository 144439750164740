<ng-content select="card-header"></ng-content>

<div class="arrow" [class.bottom]="isOpen" (click)="isOpen = !isOpen">
    <i class="fa-solid fa-angle-down"></i>
</div>

<ng-content select="card-aside"></ng-content>

<ng-container *ngIf="isOpen">
	<ng-content select="card-body"></ng-content>

	<ng-content select="card-footer"></ng-content>
</ng-container>
