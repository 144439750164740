import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '../../services/destroy.service';
import { AuthService } from '../../services/auth.service';
import { IUser } from 'src/interfaces/user.interface';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ThemeService } from '../../services/theme.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DestroyService],
})
export class HeaderComponent implements OnInit {
  @Input() public isSidebarCollapsed = true;
  @Output() public onHamburgerClick: EventEmitter<void> = new EventEmitter();

  public headerTitle = this.route.snapshot.children[0].data.title;
  public isBellOpen = false;
  public user: IUser = <IUser>this.authService.user;
  public meetingUrl = '';
  public isDarkTheme$!: Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private readonly destroy$: DestroyService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private themeService: ThemeService
  ) {}

  public ngOnInit(): void {
    this.getHeaderTitle();

    this.isDarkTheme$ = this.themeService.isDarkTheme$;

    if (this.user.role === 'student') {
      this.meetingUrl = this.user.tutors[0].meetingUrl;
    }

    if (this.user.role === 'tutor') {
      this.meetingUrl = this.user.meetingUrl;
    }
  }

  public onThemeChange(themeState: boolean): void {
    const theme = themeState ? 'dark' : 'light';
    this.themeService
      .setTheme(theme)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public handleHamburgerClick(): void {
    this.matDialog.closeAll();
    this.onHamburgerClick.emit();
  }

  private getHeaderTitle(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.headerTitle = this.route.snapshot.children[0].data.title;
    });
  }
}
