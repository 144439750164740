import { Component, Input } from '@angular/core';

@Component({
	selector: 'card-info',
	templateUrl: './card-info.component.html',
	styleUrls: ['./card-info.component.scss'],
})
export class CardInfoComponent {
	@Input() public direction: 'row' | 'column' = 'column';
}
