import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { DashboardGuard } from './shared/guards/dashboard.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { AccessGuard } from './shared/guards/access.guard';
const routes: Routes = [
	{
		path: 'dashboard',
		loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
		canActivate: [DashboardGuard],
	},
	{
		path: 'access',
		loadChildren: () => import('./auth/modules/access/access.module').then(m => m.AccessModule),
		canActivate: [AccessGuard],
	},

	{
		path: '',
		loadChildren: () => import('./auth/modules/login/login.module').then(m => m.LoginModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'signup',
		loadChildren: () => import('./auth/modules/signup/signup.module').then(m => m.SignupModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'forgot-password',
		loadChildren: () =>
			import('./auth/modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
	},
	{ path: '**', component: NotFoundComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
