import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'avatar',
})
export class AvatarPathPipe implements PipeTransform {
  transform(path = ''): string {
    return path ? path : `assets/icons/profile.svg`;
  }
}
