import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { disabledInputsTooltips } from 'src/app/constants/disabled-inputs-tooltips.constants';
import { LABELS } from 'src/app/constants/labels';
import { placeholders } from 'src/app/constants/placeholders.constants';
import { DatePeriod } from 'src/app/dashboard/admin-payout/utils/date-period';
import { isEqual } from 'src/app/utils/is-equal';
import {
  AdminFilters,
  AdminPayoutFilters,
  ISelectData,
  PaymentFilters,
  TutorFilters,
} from 'src/interfaces/filters.interface';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPanelComponent {
  @Input() public filterDataGroup!: UntypedFormGroup;
  @Input() public searchControl!: UntypedFormControl;
  @Input() public defaultFilter!:
    | AdminFilters
    | TutorFilters
    | AdminPayoutFilters
    | PaymentFilters;
  @Input() public currentDate!: Date | DatePeriod;
  @Input() public roles: ISelectData[] = [];
  @Input() public teachers: ISelectData[] = [];
  @Input() public students: ISelectData[] = [];
  @Input() public statuses: ISelectData[] = [];
  @Input() public operations: ISelectData[] = [];
  @Input() public disableMonthSelection = false;
  @Input() dateLabel = $localize`Дата`;
  @Input() dateRangePlaceholder: string = $localize`Дата регистрации`;
  @Input() showStudentTooltip = false;

  public labels = LABELS;
  public placeholders = placeholders;
  public disabledInputsTooltips = disabledInputsTooltips;
  public maxTooltipWidth = 700;

  //todo refactor this to global method or to think about this
  public checkNonNullValues(obj: any): boolean {
    const values = Object.values(obj);
    return values.every(value => value !== null);
  }

  public onResetFiltersClick(): void {
    this.filterDataGroup.reset(this.defaultFilter);
    if (this.searchControl) {
      this.searchControl.reset();
    }
  }

  public isDisableResetFilters(): boolean {
    const keys = Object.keys(this.filterDataGroup.value) as Array<
      keyof (AdminFilters | TutorFilters | PaymentFilters)
    >;

    const isFilterChange = keys.some(
      key => !isEqual(this.filterDataGroup.value[key], this.defaultFilter[key])
    );

    return !isFilterChange;
  }

  public search(): void {
    this.filterDataGroup.patchValue({
      search: this.searchControl.value.trim(),
    });
  }

  public clearSearch(): void {
    this.searchControl.setValue('');
    this.filterDataGroup.patchValue({ search: null });
  }

  public isFirstRowVisible(): boolean {
    return (
      this.filterDataGroup.contains('male') &&
      this.filterDataGroup.contains('female') &&
      this.filterDataGroup.contains('active') &&
      this.filterDataGroup.contains('inactive') &&
      this.filterDataGroup.contains('verifiedRole') &&
      this.filterDataGroup.contains('unverifiedRole') &&
      this.filterDataGroup.contains('studentWithTutor') &&
      this.filterDataGroup.contains('studentWithoutTutor') &&
      this.filterDataGroup.contains('ableAssignStudent') &&
      this.filterDataGroup.contains('notAbleAssignStudent')
    );
  }
}
