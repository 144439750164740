<div class="toggle" [formGroup]="formGroup">
  <mat-slide-toggle
    class="slide"
    color="primary"
    labelPosition="before"
    [formControlName]="controlName"
  >
    <mat-label ngClass="labelBeforeSlide">{{ label }}</mat-label>
  </mat-slide-toggle>
</div>
