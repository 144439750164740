import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDescription } from '../associated-user-modal-box/associated-user-modal-box.component';

@Component({
  selector: 'app-description-item',
  templateUrl: './description-item.component.html',
  styleUrls: ['./description-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionItemComponent {
  @Input() public description!: IDescription;
  @Input() public valueType: 'text' | 'link' | 'iconed' = 'text';
}
