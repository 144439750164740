import { Directive, Input, HostListener } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[inputStartWith]',
})
export class InputStartWithDirective {
  @Input() public inputStartWith = '';

  constructor(private hostControl: NgControl) {}

  public get control(): UntypedFormControl {
    return this.hostControl.control as UntypedFormControl;
  }

  @HostListener('focus') public onFocus(): void {
    if (this.value.length <= this.inputStartWith.length) {
      this.control?.setValue(this.inputStartWith, { emitEvent: false });
    }
  }

  @HostListener('blur') public onBlur(): void {
    if (this.value.length <= this.inputStartWith.length) {
      this.control?.setValue('', { emitEvent: false });
    }
  }

  private get value(): string {
    if (this.control?.value) {
      return this.control?.value;
    }
    return '';
  }
}
