import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IInfoCard } from 'src/interfaces/info-card.interface';
import { ThemeService } from '../../services/theme.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoCardComponent {
  @Input() public data!: IInfoCard;
  @Input() public logoUrl = '';
  @Input() public statusClass = '';
  @Input() public withContextMenu = false;
  @Output() public onCallContextMenu: EventEmitter<MouseEvent> =
    new EventEmitter();

  public isDarkTheme$: Observable<boolean> = this.themeService.isDarkTheme$;

  constructor(private themeService: ThemeService) {}

  public handleClick(event: MouseEvent): void {
    this.onCallContextMenu.emit(event);
  }
}
