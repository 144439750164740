import {
  Language,
  SkillLevel,
  SourceOfDiscovery,
  Teaching,
} from 'src/interfaces/study.interface';

export const skillLevelI18n: Record<SkillLevel, string> = {
  [SkillLevel.Beginner]: $localize`Начинающий`,
  [SkillLevel.Basic]: $localize`Средний`,
  [SkillLevel.Advanced]: $localize`Продвинутый`,
};

export const teachingI18n: Record<Teaching, string> = {
  [Teaching.Informatics]: $localize`Информатика`,
  [Teaching.WebDesign]: 'Web Design',
  [Teaching.English]: 'English',
  [Teaching.HTML]: 'HTML',
  [Teaching.CSS]: 'CSS',
  [Teaching.JavaScript]: 'JavaScript',
  [Teaching.Angular]: 'Angular',
  [Teaching.React]: 'React',
  [Teaching.Vue]: 'Vue',
  [Teaching.Python]: 'Python',
  [Teaching.Java]: 'Java',
  [Teaching.Nodejs]: 'Nodejs',
  [Teaching.QA]: 'QA',
};

export const languageI18n: Record<Language, string> = {
  [Language.Russian]: `Русский`,
  [Language.Ukrainian]: `Українська`,
  [Language.English]: `English`,
};

export const sourceOfDiscoveryI18n: Record<SourceOfDiscovery, string> = {
  [SourceOfDiscovery.instagramFacebook]: `Instagram/Facebook`,
  [SourceOfDiscovery.google]: `Google`,
  [SourceOfDiscovery.buki]: `Buki`,
  [SourceOfDiscovery.olx]: `Olx`,
  [SourceOfDiscovery.recommendations]: $localize`Рекомендации`,
};
