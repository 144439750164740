import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { NotificationsComponent } from './notifications.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { TooltipModule } from '../../modules/tooltip/tooltip.module';
import { MaterialModule } from 'src/app/material/material.module';

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationComponent,
    NotificationListComponent,
  ],
  exports: [NotificationsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
  ],
})
export class NotificationsModule {}
