<header>
  <ng-content select="modal-title"></ng-content>
  <button *ngIf="withCloseButton" class="mat-dialog-close" mat-icon-button [matDialogClose]="result">
    <mat-icon class="d-md-none">close</mat-icon>
    <mat-icon class="icon-arrow d-none d-md-block">arrow_back</mat-icon>
  </button>
</header>

<ng-content select="modal-body"></ng-content>

<ng-content select="modal-footer"></ng-content>
<ng-content select="modal-aside"></ng-content>