import { Component, Input, OnChanges, HostBinding } from '@angular/core';
import { StatusColor, StatusData } from '../../interfaces/status.interface';

@Component({
	selector: 'status',
	template: '{{ text }}',
	host: { class: 'e-status' },
})
export class StatusComponent<T extends string | number> implements OnChanges {
	@Input() public data!: StatusData<T>;
	@Input() public status!: T;

	@HostBinding('class') public color!: StatusColor;

	public text: string = '';

	public ngOnChanges(): void {
		const item = this.data[this.status];

		this.color = item.color;
		this.text = item.text;
	}
}
