<div
  class="notifications"
  [tooltip]="messageTooltip"
  [tooltipDisabled]="!!messages.length"
  tooltipDirection="bottom-start"
  tooltipTrigger="click"
  [class.notifications_active]="messages.length"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="isBellOpen = !isBellOpen"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M19.5183 28.8029C19.3135 29.167 19.0191 29.4692 18.6649 29.6793C18.3106 29.8894 17.9089 30 17.5 30C17.0911 30 16.6894 29.8894 16.3351 29.6793C15.9809 29.4692 15.6865 29.167 15.4817 28.8029M24.5 13.681C24.5 11.6443 23.7627 9.69047 22.4502 8.25029C21.1365 6.8101 19.355 6 17.5 6C15.6438 6 13.8635 6.8089 12.5498 8.25029C11.2373 9.69047 10.5 11.6443 10.5 13.681C10.5 22.6425 7 25.2024 7 25.2024H28C28 25.2024 24.5 22.6425 24.5 13.681Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</div>
<ng-template #messageTooltip i18n> Уведомлений нет </ng-template>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isBellOpen"
  cdkConnectedOverlayPanelClass="panel"
  [cdkConnectedOverlayOffsetY]="10"
>
  <div
    class="notifications__box box"
    *ngIf="messages.length"
    [class.dark-theme]="isDarkTheme$ | async"
    #notificationsBox
  >
    <div class="notifications__header d-tablet-none">
      <span class="notifications__title" i18n>Новые сообщения</span>
      <span class="notifications__button" (click)="deleteAllMessages()" i18n
        >Скрыть все</span
      >
    </div>
    <div class="notifications__container d-tablet-none">
      <app-notification
        (onRedirect)="closeNotificationPanel()"
        *ngFor="let message of messages"
        [message]="message"
      ></app-notification>
    </div>
    <app-notification-list
      class="d-tablet-flex d-none"
      (onHideAllClick)="deleteAllMessages()"
    ></app-notification-list>
  </div>
</ng-template>
