<form *ngIf="filterDataGroup" [formGroup]="filterDataGroup" class="filters">
  <div class="first-row" *ngIf="isFirstRowVisible()">
    <div class="toggles">
      <app-label-container class="toggles__item" [labelText]="labels.gender">
        <app-toggle
          [label]="labels.male"
          [formGroup]="filterDataGroup"
          controlName="male"
        ></app-toggle>
        <app-toggle
          [label]="labels.female"
          [formGroup]="filterDataGroup"
          controlName="female"
        ></app-toggle>
      </app-label-container>
      <app-label-container class="toggles__item" [labelText]="labels.status">
        <app-toggle
          [label]="labels.active"
          [formGroup]="filterDataGroup"
          controlName="active"
        ></app-toggle>
        <app-toggle
          [label]="labels.inactive"
          [formGroup]="filterDataGroup"
          controlName="inactive"
        ></app-toggle>
      </app-label-container>
      <app-label-container
        class="toggles__item"
        [labelText]="labels.verification"
      >
        <app-toggle
          [label]="labels.verified"
          [formGroup]="filterDataGroup"
          controlName="verifiedRole"
        ></app-toggle>
        <app-toggle
          [label]="labels.unverified"
          [formGroup]="filterDataGroup"
          controlName="unverifiedRole"
        ></app-toggle>
      </app-label-container>
      <app-label-container
        class="toggles__item"
        [labelText]="labels.students"
        appOneToggleSelected
        [firstToggle]="filterDataGroup.controls['studentWithTutor']"
        [secondToggle]="filterDataGroup.controls['studentWithoutTutor']"
      >
        <app-toggle
          [label]="labels.tutorAssigned"
          [formGroup]="filterDataGroup"
          controlName="studentWithTutor"
        ></app-toggle>
        <app-toggle
          [label]="labels.tutorNotAssigned"
          [formGroup]="filterDataGroup"
          controlName="studentWithoutTutor"
        ></app-toggle>
      </app-label-container>
      <app-label-container
        class="toggles__item"
        [labelText]="labels.assignable"
        appOneToggleSelected
        [firstToggle]="filterDataGroup.controls['ableAssignStudent']"
        [secondToggle]="filterDataGroup.controls['notAbleAssignStudent']"
      >
        <app-toggle
          [label]="labels.ableAssignStudent"
          [formGroup]="filterDataGroup"
          controlName="ableAssignStudent"
        ></app-toggle>
        <app-toggle
          [label]="labels.notAbleAssignStudent"
          [formGroup]="filterDataGroup"
          controlName="notAbleAssignStudent"
        ></app-toggle>
      </app-label-container>
    </div>
    <div class="search">
      <app-label-container [labelText]="labels.search">
        <app-input
          class="search__item"
          [formControl]="searchControl"
          [withResetButton]="true"
          (enterKeyup)="search()"
          (clearField)="clearSearch()"
          [iconPath]="'/assets/icons/magnifier_input.svg'"
        ></app-input>
      </app-label-container>
      <button
        class="btn btn-primary search__btn"
        [disabled]="!searchControl.value"
        (click)="search()"
        i18n
      >
        Найти
      </button>
    </div>
  </div>
  <div class="second-row">
    <div class="selects">
      <app-label-container
        *ngIf="filterDataGroup.controls['student']"
        class="selects__item"
        [labelText]="labels.students"
        [tooltip]="disabledInputsTooltips.tutorReportStudents"
        [tooltipDisabled]="
          !showStudentTooltip || !filterDataGroup.controls['student'].disabled
        "
        [maxWidth]="maxTooltipWidth"
      >
        <app-select
          [formGroup]="filterDataGroup"
          controlName="student"
          [placeholderText]="placeholders.selectStudent"
          [withResetButton]="true"
          [optionsList]="students"
        ></app-select>
      </app-label-container>
      <app-label-container
        *ngIf="filterDataGroup.contains('role')"
        class="selects__item"
        [labelText]="labels.role"
      >
        <app-select
          [formGroup]="filterDataGroup"
          [placeholderText]="placeholders.selectRole"
          controlName="role"
          [optionsList]="roles"
          [withResetButton]="true"
        ></app-select>
      </app-label-container>
      <app-label-container
        *ngIf="filterDataGroup.controls['teacher']"
        class="selects__item"
        [labelText]="labels.tutors"
      >
        <app-select
          [formGroup]="filterDataGroup"
          controlName="teacher"
          [placeholderText]="placeholders.selectTutor"
          [optionsList]="teachers"
          [withResetButton]="true"
        ></app-select>
      </app-label-container>
      <app-label-container
        *ngIf="filterDataGroup.contains('range')"
        class="selects__item"
        [labelText]="dateLabel"
      >
        <date-range-picker
          [class.empty]="
            checkNonNullValues(filterDataGroup.get('range')?.value)
          "
          formControlName="range"
          [placeholderText]="dateRangePlaceholder"
          [currentDate]="currentDate"
          [disableMonthSelection]="disableMonthSelection"
        ></date-range-picker>
      </app-label-container>
      <app-label-container
        *ngIf="filterDataGroup.contains('status')"
        class="selects__item"
        [labelText]="labels.status"
      >
        <app-select
          [formGroup]="filterDataGroup"
          controlName="status"
          [optionsList]="statuses"
          [withResetButton]="true"
          [placeholderText]="placeholders.selectStatus"
        ></app-select>
      </app-label-container>
      <app-label-container
        *ngIf="filterDataGroup.contains('operation')"
        class="selects__item"
        [labelText]="labels.operation"
      >
        <app-select
          [formGroup]="filterDataGroup"
          controlName="operation"
          [optionsList]="operations"
          [withResetButton]="true"
          [placeholderText]="placeholders.selectOperation"
        ></app-select>
      </app-label-container>
    </div>
    <button
      mat-stroked-button
      class="reset-btn d-md-none"
      (click)="onResetFiltersClick()"
      [disabled]="isDisableResetFilters()"
      i18n
    >
      Сбросить фильтры
    </button>
  </div>
</form>
