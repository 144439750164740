import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lessons-color-info',
  templateUrl: './lessons-color-info.component.html',
  styleUrls: ['./lessons-color-info.component.scss'],
})
export class LessonsColorInfoComponent {
  @Input() public isShow = true;
}
