import { Component } from '@angular/core';
import { OptionService } from '../../services/option.service';
import { languagesObj } from 'src/app/constants/language.constants';
import { LanguageService } from '../../services/language.service';
import { AuthService } from '../../services/auth.service';
import { Locale } from 'src/interfaces/user.interface';
import { DestroyService } from '../../services/destroy.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  providers: [DestroyService],
})
export class LanguageSelectComponent {
  public languageOptions = OptionService.transform(languagesObj);

  constructor(
    private languageService: LanguageService,
    private authService: AuthService,
    private readonly destroy$: DestroyService
  ) {}

  public get currentLanguage(): string {
    return this.languageService.language;
  }

  public setLanguage(language: Locale): void {
    if (!this.authService.user) {
      window.location.href = window.location.href.replace(
        this.languageService.language,
        language
      );
    }
    this.authService
      .updateUserLocale(language)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () =>
          (window.location.href = window.location.href.replace(
            this.languageService.language,
            language
          ))
      );
  }
}
