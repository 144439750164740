import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
import {
  ModalTitleDirective,
  ModalBodyDirective,
  ModalFooterDirective,
  ModalAsideDirective,
} from './directives/modal.directive';
import { MaterialModule } from 'src/app/material/material.module';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [
    ModalComponent,
    ModalTitleDirective,
    ModalBodyDirective,
    ModalFooterDirective,
    ModalAsideDirective
  ],
  exports: [
    ModalComponent,
    ModalTitleDirective,
    ModalBodyDirective,
    ModalFooterDirective,
    ModalAsideDirective
  ],
})
export class ModalModule {}
