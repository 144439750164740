<mat-form-field
  class="input"
  [class.input-icon]="!!iconPath"
  [class.input-required]="isRequired"
  [class.input-with-select]="!!country"
  [attr.disabled]="true"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    [formControl]="formControl"
    matInput
    [inputStartWith]="startWith"
    [passwordTogle]="!withHideButton"
    (keyup.enter)="onEnterKeyup()"
    (keyup.backspace)="onBackspaceKeyup()"
    [maxlength]="maxLength"
    [disabled]="false"
    [placeholder]="placeholder"
    [mask]="mask"
    [prefix]="prefix"
    [type]="type"
    autocomplete="off"
  />
  <button
    type="button"
    class="reset-button"
    *ngIf="withResetButton && formControl.value"
    mat-button
    matSuffix
    mat-icon-button
    (click)="onClearField()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <img matPrefix *ngIf="!!iconPath" [src]="iconPath" />
  <mat-error><ng-content select="[error]"></ng-content></mat-error>
</mat-form-field>
<ng-content select="[select]"></ng-content>
