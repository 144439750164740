import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from '../material/material.module';
import { NotificationsModule } from './components/notifications/notifications.module';
import { CardModule } from './modules/card/card.module';
import { ModalModule } from './modules/modal/modal.module';
import { StatusModule } from './modules/status/status.module';
import { TooltipModule } from './modules/tooltip/tooltip.module';
// Directives
import { OneToggleSelectedDirective } from './components/filter-panel/directives/one-toggle-selected/one-toggle-selected.directive';
//Pipes
import { CreditCardMaskPipe } from './pipes/credit-card-mask.pipe';
import { PhoneMaskPipe } from './pipes/phone-mask.pipe';
//Services
import { DestroyService } from './services/destroy.service';
// Components
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ConfirmPopUpComponent } from './components/confirm-pop-up/confirm-pop-up.component';
import { NotificationWindowComponent } from './components/notification-window/notification-window.component';
import { MonthComponent } from './components/month/month.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { LessonActionsDialogComponent } from './components/lesson-actions-dialog/lesson-actions-dialog.component';
import { ActionsStudyPeriodsComponent } from './components/actions-study-periods/actions-study-periods.component';
import { CountrySelectComponent } from './components/country-select/country-select.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { ActionButtonsComponent } from 'src/app/shared/components/action-buttons/action-buttons.component';
import { ActionsInfoComponent } from './components/actions-info/actions-info.component';
import { AmountFieldComponent } from './components/amount-field/amount-field.component';
import { NoDataMessageComponent } from './components/no-data-message/no-data-message.component';
import { AssociatedUserModalBoxComponent } from './components/associated-user-modal-box/associated-user-modal-box.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { InputComponent } from './components/input/input.component';
import { LabelContainerComponent } from './components/label-container/label-container.component';
import { LessonsColorInfoComponent } from './components/lessons-color-info/lessons-color-info.component';
import { SelectComponent } from './components/select/select.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { UserInfoCardComponent } from './components/user-info-card/user-info-card.component';
import { BlockPeriodActionsComponent } from './components/block-period-actions/block-period-actions.component';
import { DescriptionItemComponent } from './components/description-item/description-item.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { LanguageSelectComponent } from './components/language-select/language-select.component';
import { MobileFiltersWindowComponent } from './components/mobile-filters-window/mobile-filters-window.component';
import { MobileSortWindowComponent } from './components/mobile-sort-window/mobile-sort-window.component';
import { MonthSwitcherComponent } from './components/month-switcher/month-switcher.component';
import { NotificationFormComponent } from './components/notification-form/notification-form.component';
import { NotificationWindowModalComponent } from './components/notification-window-modal/notification-window-modal.component';
import { PaymentMethodSelectComponent } from './components/payment-method-select/payment-method-select.component';
import { PayoutCardFormComponent } from './components/payout-card-form/payout-card-form.component';
import { PayoutFormComponent } from './components/payout-form/payout-form.component';
import { PayoutPaypalFormComponent } from './components/payout-paypal-form/payout-paypal-form.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ThemeToggleComponent } from './components/theme-toggle/theme-toggle.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { PasswordTogleDirective } from './directives/password-togle/password-togle.directive';
import { InputStartWithDirective } from './directives/input-start-with/input-start-with.directive';
import { AvatarPathPipe } from './pipes/avatar-path.pipe';
import { LessonPricePipe } from './pipes/lesson-price.pipe.ts.pipe';

const components = [
  SidebarComponent,
  HeaderComponent,
  NotFoundComponent,
  ConfirmPopUpComponent,
  NotificationWindowComponent,
  MonthComponent,
  ContextMenuComponent,
  LessonActionsDialogComponent,
  ActionsStudyPeriodsComponent,
  CountrySelectComponent,
  PhoneInputComponent,
  DateRangePickerComponent,
  ActionButtonsComponent,
  ActionsInfoComponent,
  AmountFieldComponent,
  NoDataMessageComponent,
  AssociatedUserModalBoxComponent,
  UserInfoCardComponent,
  FilterPanelComponent,
  LabelContainerComponent,
  SelectComponent,
  ToggleComponent,
  InputComponent,
  LessonsColorInfoComponent,
  DatePickerComponent,
  TabsComponent,
  NotificationFormComponent,
  NotificationWindowModalComponent,
  DescriptionItemComponent,
  MobileFiltersWindowComponent,
  InfoCardComponent,
  PaymentMethodSelectComponent,
  PayoutCardFormComponent,
  PayoutPaypalFormComponent,
  PayoutFormComponent,
  MobileSortWindowComponent,
  LanguageSelectComponent,
  BlockPeriodActionsComponent,
  MonthSwitcherComponent,
  ThemeToggleComponent,
  TextareaComponent,
];

const pipes = [
  PhoneMaskPipe,
  CreditCardMaskPipe,
  AvatarPathPipe,
  LessonPricePipe,
];

const directives = [
  OneToggleSelectedDirective,
  PasswordTogleDirective,
  InputStartWithDirective,
];

@NgModule({
  declarations: [...components, ...pipes, ...directives],
  exports: [...components, ...pipes, ...directives],
  providers: [DestroyService],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ModalModule,
    NgxMaskModule.forRoot(),
    TooltipModule,
    CardModule,
    StatusModule,
    NotificationsModule,
  ],
})
export class SharedModule {}
