<mat-form-field class="input" (click)="picker.open()" [class.remove-separator]="!end">
	<mat-label>{{ placeholderText }}</mat-label>
	<mat-date-range-input [rangePicker]="picker">
		<input matStartDate [placeholder]="placeholders.start" [(ngModel)]="start" />
		<input matEndDate [placeholder]="placeholders.end" [(ngModel)]="end" />
	</mat-date-range-input>
	<span class="calendar-arrow mat-select-arrow"></span>
	<mat-date-range-picker #picker [startAt]="startAt" [calendarHeaderComponent]="customHeader">
		<mat-date-range-picker-actions>
			<button class="btn btn-primary" matDatepickerApply (click)="onClosed()" i18n>Применить</button>
		</mat-date-range-picker-actions>
	</mat-date-range-picker>
	<button mat-button *ngIf="start" matSuffix mat-icon-button (click)="clearStartDate($event)">
		<mat-icon>close</mat-icon>
	</button>
</mat-form-field>
