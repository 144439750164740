import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent {
  @Input() public label = '';
  @Input() public formGroup!: UntypedFormGroup;
  @Input() public controlName!: string;
  // TODO To think: Implement ControlValueAccessor through NgControl if this component will be used without FormGroup
}
