import { Injectable } from '@angular/core';
import { IGroupList } from 'src/interfaces/group.interface';
import { DatePipe } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class GroupService {
	constructor(private datePipe: DatePipe) {}

  // callback to get field for grouping list by this date field (messages.createdAt; payments.date)
	public groupByDate<T>(list: T[], callback: (message: T) => string | Date): IGroupList<T>[] {
		let resultArray: IGroupList<T>[] = [];

		for (let i = 0; i < list.length; i++) {
			const group = resultArray.find(
				item =>
					this.datePipe.transform(item.date, 'shortDate') ===
					this.datePipe.transform(callback(list[i]), 'shortDate')
			);

			if (group) {
				group.list = [...group.list, list[i]];
			} else {
				resultArray.push({
					date: new Date(callback(list[i])),
					list: [list[i]],
				});
			}
		}
		return resultArray;
	}
}
