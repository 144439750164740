import { Pipe, PipeTransform } from '@angular/core';
import { Country } from 'src/interfaces/country.interface';
import countries from '../constants/country.json'
import { MaskApplierService } from 'ngx-mask';

@Pipe({
  name: 'phoneMask'
})
export class PhoneMaskPipe implements PipeTransform {
  constructor(private readonly _maskService: MaskApplierService) {}

  transform(phone: Nullable<string>, country: Nullable<Country | `${Country}`>): Nullable<string> {
    const countryData = countries.find((el) => el.code === country);

    if(!countryData || !phone){
      return phone;
    }
    
    const {prefix, mask} = countryData;
    const _phone = phone?.slice(prefix.length - 1);

    return `${prefix}${this._maskService.applyMask(_phone, mask)}`;
  }

}
