export enum NotificationClass {
    first = 'class1',
    second = 'class2',
    third = 'class3'
}

export enum NotificationClassesDestination{
    email = 'email',
    telegram = 'telegram'
}

export type TNotificationClass1Desctination = [NotificationClassesDestination?, NotificationClassesDestination?]
export type TNotificationClass2And3Desctination = [NotificationClassesDestination, NotificationClassesDestination?]

export interface INotificationClassesDestinaion{
    [NotificationClass.first]: TNotificationClass1Desctination,
    [NotificationClass.second]: TNotificationClass2And3Desctination,
    [NotificationClass.third]: TNotificationClass2And3Desctination
}