import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonsComponent {
  @Input() public cancelButtonTitle = $localize`Отменить`;
  @Input() public cancelButtonClass = 'stroked';
  @Input() public confirmButtonTitle = $localize`Сохранить`;
  @Input() public isConfirmButtonDisable = false;
  @Input() public isCancelButtonDisable = false;

  @Output() public buttonClick: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() public confirmClick = new EventEmitter<void>();
  @Output() public cancelClick = new EventEmitter<void>();

  public onCancelClick(): void {
    this.buttonClick.emit(false); // TODO remove this one
    this.cancelClick.emit();
  }

  public onConfirmClick(): void {
    this.buttonClick.emit(true); // TODO remove this one
    this.confirmClick.emit();
  }
}
