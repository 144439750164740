import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[passwordTogle]',
})
export class PasswordTogleDirective implements AfterViewInit {
  @Input('passwordTogle') public passwordTogle: string | boolean = true;
  public visible!: boolean;

  public iconElement = document.createElement('div');

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  public ngAfterViewInit(): void {
    if (!this.passwordTogle) {
      this.iconElement.classList.add('password-toggle-icon');

      this.elementRef.nativeElement.parentNode!.insertBefore(
        this.iconElement,
        this.elementRef.nativeElement
      );
      this.elementRef.nativeElement.type = 'password';
      this.iconElement.addEventListener(
        'click',
        this.togglePasswordType.bind(this)
      );
    }
  }

  private togglePasswordType(): void {
    this.visible = !this.visible;
    if (this.visible) {
      this.iconElement.classList.add('eye-on');
      this.elementRef.nativeElement.type = 'text';
      return;
    }
    this.iconElement.classList.remove('eye-on');
    this.elementRef.nativeElement.type = 'password';
  }
}
