import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { SnackBarData } from '../../interfaces/snack-bar-data.interface';

@Component({
  selector: 'app-snack-bar',
  template:
    '<span class="snack-bar__text" data-test="userNotification">{{data.message}}</span>',
  styleUrls: ['./confirm-snack-bar.component.scss'],
})
export class ConfirmSnackBarComponent {
  @HostBinding('class') public readonly type = this.data.type;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {}
}
