<ng-container [formGroup]="formGroup">
  <mat-form-field class="input">
    <input
      matInput
      formControlName="email"
      i18n-placeholder
      placeholder="Введите данные"
    />
    <mat-error>{{ ERROR.required }}</mat-error>
  </mat-form-field>
  <img
    *ngIf="isCopy"
    (click)="onCopyCard()"
    class="copy-paste-img"
    src="assets/icons/copy_paste.svg"
    alt="copy-paste icon"
  />
</ng-container>
