import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IMessage } from 'src/interfaces/messages.interface';
import { takeUntil } from 'rxjs/operators';
import { IGroupList } from 'src/interfaces/group.interface';
import { NotificationService } from 'src/app/shared/components/notifications/services/notification.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { DestroyService } from 'src/app/shared/services/destroy.service';
import { GroupService } from 'src/app/shared/services/group.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  providers: [DestroyService],
})
export class NotificationListComponent implements OnInit {
  @Output() public onRedirect: EventEmitter<void> = new EventEmitter();
  @Output() public onHideAllClick: EventEmitter<void> = new EventEmitter();

  public messages: IMessage[] = [];
  public messageDataGroup: IGroupList<IMessage>[] = [];

  constructor(
    public notificationService: NotificationService,
    private readonly destroy$: DestroyService,
    private groupService: GroupService,
    private languageService: LanguageService
  ) {}

  public get language(): string {
    return this.languageService.language;
  }

  public ngOnInit(): void {
    this.notificationService.messages$
      .pipe(takeUntil(this.destroy$))
      .subscribe(messages => {
        this.messages = messages;
        this.messageDataGroup = this.groupService.groupByDate(
          this.messages,
          message => message.createdAt
        );
      });
  }

  public handleRedirect(): void {
    this.onRedirect.emit();
  }

  public deleteAllMessages(): void {
    this.onHideAllClick.emit();
  }
}
