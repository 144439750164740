import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PaymentMethodOptions } from 'src/interfaces/option.interface';

@Component({
	selector: 'app-payment-method-select',
	templateUrl: './payment-method-select.component.html',
	styleUrls: ['./payment-method-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PaymentMethodSelectComponent,
			multi: true,
		},
	],
})
export class PaymentMethodSelectComponent implements ControlValueAccessor {
	@Input() public disabled = false;

	public options: PaymentMethodOptions[] = [
		{
      value: 'card',
			text: $localize `Банковская карта`,
			image: 'assets/icons/default-payment-icon.svg',
		},
		{
      value: 'payPal',
			text: 'PayPal',
			image: 'assets/icons/paypal-mini.svg',
		},
	];

	public selectedOptionObj!: PaymentMethodOptions;
	public value = this.options[0].value;

	private onChange = (value: string): void => {};
	private onTouch = () => {};

  constructor(private changeDetector: ChangeDetectorRef) {}

	public writeValue(value: string): void {
		this.value = value;
		this.selectedOptionObj = this.options.find(method => method.value === this.value) as PaymentMethodOptions;
   	 	this.changeDetector.detectChanges();
	}

	public registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}
	public registerOnTouched(fn: () => void): void {
		this.onTouch = fn;
	}

	public onPaymentMethodSelect(event: string): void {
		this.value = event;
		this.selectedOptionObj = this.options.find(method => method.value === this.value) as PaymentMethodOptions;
		this.onTouch();
		this.onChange(event);
    	this.changeDetector.detectChanges();
	}
}
