import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  NotificationClassesDestination,
  TNotificationClass1Desctination,
  TNotificationClass2And3Desctination,
} from 'src/interfaces/notification.interface';
import { IUser } from 'src/interfaces/user.interface';
import { DestroyService } from '../../services/destroy.service';
import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const { email, telegram } = NotificationClassesDestination;

@Component({
  selector: 'app-notification-form',
  templateUrl: './notification-form.component.html',
  styleUrls: ['./notification-form.component.scss'],
  providers: [DestroyService],
})
export class NotificationFormComponent implements OnInit {
  @Input() public notificationFormGroup!: UntypedFormGroup;

  @Input() public user!: IUser;
  public readonly NotificationClassesDestination =
    NotificationClassesDestination;

  public class1 = new UntypedFormGroup({
    [email]: new UntypedFormControl(),
    [telegram]: new UntypedFormControl(),
  });

  public class2 = new UntypedFormGroup({
    [email]: new UntypedFormControl(),
    [telegram]: new UntypedFormControl(),
  });

  public class3 = new UntypedFormGroup({
    [email]: new UntypedFormControl(),
    [telegram]: new UntypedFormControl(),
  });

  public get isEmailDisabled2(): boolean {
    return this.class2.value[email] && !this.class2.value[telegram];
  }

  public get isTelegramDisabled2(): boolean {
    return !this.class2.value[email] && this.class2.value[telegram];
  }

  public get isEmailDisabled3(): boolean {
    return this.class3.value[email] && !this.class3.value[telegram];
  }

  public get isTelegramDisabled3(): boolean {
    return !this.class3.value[email] && this.class3.value[telegram];
  }

  constructor(private readonly destroy$: DestroyService) {}

  public ngOnInit(): void {
    this.setNotificationData(
      this.class1,
      this.user.notificationClassesDestination.class1
    );
    this.setNotificationData(
      this.class2,
      this.user.notificationClassesDestination.class2
    );
    this.setNotificationData(
      this.class3,
      this.user.notificationClassesDestination.class3
    );

    merge(
      this.class1.valueChanges,
      this.class2.valueChanges,
      this.class3.valueChanges
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.notificationFormGroup.patchValue({
          notificationClassesDestination: {
            class1: this.getNotificationData(this.class1),
            class2: this.getNotificationData(this.class2),
            class3: this.getNotificationData(this.class3),
          },
        });
      });
  }

  private setNotificationData(
    form: UntypedFormGroup,
    data: TNotificationClass1Desctination | TNotificationClass2And3Desctination
  ): void {
    form.setValue({
      [email]: data.includes(email),
      [telegram]: data.includes(telegram),
    });
  }

  private getNotificationData(
    form: UntypedFormGroup
  ): TNotificationClass1Desctination | TNotificationClass2And3Desctination {
    if (form.value[email] && form.value[telegram]) {
      return [email, telegram];
    }
    if (form.value[email]) {
      return [email];
    }
    if (form.value[telegram]) {
      return [telegram];
    }

    return [];
  }
}
