import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { msInDay } from 'src/app/constants/time.constants';
import { DatePeriod } from 'src/app/dashboard/admin-payout/utils/date-period';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-month-switcher',
  templateUrl: './month-switcher.component.html',
  styleUrls: ['./month-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthSwitcherComponent {
  @Input() public dateRange!: DatePeriod;
  @Input() public isDisabled: boolean = false;
  @Input() public type: 'month' | 'day' = 'month';
  @Input() public withCalendar = false;
  @Output() public onChangeDate: EventEmitter<DatePeriod> = new EventEmitter();

  public isCalendarOpen = false;

  constructor(private languageService: LanguageService) {}

  public get language(): string {
    return this.languageService.language;
  }

  public setNewDate(direction: 'next' | 'previous'): void {
    if (!this.isDisabled) {
      const nextDatePeriod = this.type === 'month'
      ? this.getNewMonth(direction)
      : this.getNewDay(direction);

    this.onChangeDate.emit(nextDatePeriod);
    }
  }

  public getNewMonth(direction: 'next' | 'previous'): DatePeriod {
    const newDate = new Date(this.dateRange.start);
    const month = direction === 'next'
      ? this.dateRange.start.getMonth() + 1
      : this.dateRange.start.getMonth() - 1;
    return new DatePeriod(new Date(newDate.setMonth(month)));
  }

  public getNewDay(direction: 'next' | 'previous'): DatePeriod {
    const newDate = direction === 'next'
      ? +this.dateRange.start + msInDay
      : +this.dateRange.end - msInDay;
    return new DatePeriod(new Date(newDate), null);
  }

  public openCalendar(): void {
    if (this.withCalendar) {
      this.isCalendarOpen = true;
    }
  }

  public closeCaledar(): void {
    this.isCalendarOpen = false;
  }

  public onSelectedDate(date: Date): void {
    this.closeCaledar();
    this.onChangeDate.emit(new DatePeriod(date, null));
  }

  public get isDatesInSameMonth(): boolean {
    const month = this.dateRange.start?.getMonth() === this.dateRange.end?.getMonth();
    const year = this.dateRange.start?.getMonth() === this.dateRange.end?.getMonth();
    return !!(month && year);
  }
}
