import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class DashboardGuard  {
	constructor(private auth: AuthService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		if (!this.auth.isAuthenticated()) {
			this.router.navigate([''], { queryParams: { accessDenied: true } });
			return of(false);
		}

		if (!this.auth.hasAccess()) {
			return this.accessRedirect();
		}

		return of(true);
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.canActivate(childRoute, state);
	}

	private accessRedirect(): Observable<boolean> {
		const userAccess = this.auth.user?.role === 'student' ? 'without-teachers' : 'unverified';

		this.router.navigate([`access/${userAccess}`]);

		return of(false);
	}
}
