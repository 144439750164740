<section class="error">
  <div class="wrapper">
    <div class="info-wrapper">
      <span class="title" i18n>Мы не смогли найти эту страницу</span>
      <span class="description"
        i18n>Проверьте URL-адрес или вернитесь на главную страницу</span
      >
      <button class="btn" (click)="backClicked()" i18n>Назад</button>
    </div>
  </div>
</section>
