<div class="item">
  <mat-form-field class="input" [class.input-required]="isRequired" [class.input-valid]="isValid" >
    <mat-label i18n>Телефон</mat-label>
    <input
      matInput
      placeholder="+00 (000)-00-00"
      [prefix]="prefix"
      [mask]="mask"
      [ngModel]="value"
      (ngModelChange)="onInputChange($event)"
      [errorStateMatcher]="phoneErrorMatcher"
      data-test="userPhoneField"
    />
    <mat-error *ngIf="!isValid" data-test="userPhoneError">{{ ERROR.incorrectNumber }}</mat-error>
    <mat-error *ngIf="isRequired && isValid" data-test="userPhoneError">{{ ERROR.required }}</mat-error>
    <button
      mat-button
      *ngIf="value"
      matSuffix
      mat-icon-button
      (click)="clearPhone()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
