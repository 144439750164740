import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpContextToken,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackBarService } from 'src/app/shared/modules/snack-bar/services/snack-bar.service';

export const NOTIFY_ERROR = new HttpContextToken<boolean>(() => true);

const serverErrorMessage = $localize`Сервер сейчас не работает`;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snackBarService: SnackBarService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(errorResp => {
        if (
          errorResp instanceof HttpErrorResponse &&
          request.context.get(NOTIFY_ERROR)
        ) {
          const message = errorResp.error.message || serverErrorMessage;
          this.snackBarService.error(message);
        }

        return throwError(errorResp);
      })
    );
  }
}
