import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';

import { ICalendarItem, IRange } from 'src/interfaces/calendar.interface';
import {
  minutesInHour,
  msInHalfHour,
  msInMinute,
} from 'src/app/constants/time.constants';
import { ILesson, LessonStatus } from 'src/interfaces/lesson.interface';
import { IStudyPeriod } from 'src/interfaces/study-period.interface';
import { lessonStatusI18n } from 'src/app/i18n/lessonStatus.I18n';
import { IStudentUser } from 'src/interfaces/user.interface';
import { defaultLessonDuration } from 'src/app/constants/calendar.conctants';
import { BehaviorSubject } from 'rxjs';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { placeholders } from 'src/app/constants/placeholders.constants';

@Component({
  selector: 'app-lesson-actions-dialog',
  templateUrl: './lesson-actions-dialog.component.html',
  styleUrls: ['./lesson-actions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonActionsDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('textArea') public textarea!: ElementRef<HTMLTextAreaElement>;
  public dialogForm: UntypedFormGroup = new UntypedFormGroup({});
  public readonly lessonStatusI18n = lessonStatusI18n;

  public isEditing = false;
  public userFullName = '';
  public title = '';
  public lessonStatus!: LessonStatus;
  public formTitle = '';
  public role = '';
  public defaultLessonTimeRange!: IRange<Date>;
  public startLessonTime: Date[] = [];
  public endLessonTime: Date[] = [];
  public students: IStudentUser[] = [];
  public availableTime = '';

  public placeholders = placeholders;

  public readonly initialValue$ = new BehaviorSubject(
    this.dialogForm.getRawValue()
  );

  public readonly isDirty$ = dirtyCheck(this.dialogForm, this.initialValue$, {
    debounce: 0,
    useBeforeunloadEvent: false,
  });

  constructor(
    private dialogRef: MatDialogRef<LessonActionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: ICalendarItem
  ) {}

  public ngOnInit(): void {
    this.initialMatDialog();
    this.setDefaultFormDate();
  }

  public ngAfterViewInit(): void {
    if (this.textarea) {
      this.textarea.nativeElement.disabled = !this.isTutor;
      this.autoResize();
    }
  }

  public get isRepeatCheckboxDisabled(): boolean {
    return (
      this.dialogData.studyPeriod?.repeat !== 'every week' ||
      !!(
        this.dialogData.lesson && this.dialogData.lesson.repeat !== 'every week'
      )
    );
  }

  private initialMatDialog(): void {
    this.isEditing = !!this.dialogData.lesson;
    this.title = this.isEditing
      ? $localize`Редактирование урока`
      : $localize`Создание урока`;
    this.defaultLessonTimeRange = this.getDefaultLessonDateTime(
      this.dialogData.lesson
    );
    this.role = <string>this.dialogData.userRole;
    this.lessonStatus = <LessonStatus>this.dialogData.lesson?.status;
    this.formTitle = this.dialogData.lesson
      ? $localize`Изменить время урока`
      : $localize`Время урока`;
    this.startLessonTime = this.getAvailableStartTime(
      <IStudyPeriod>this.dialogData.studyPeriod
    );
    this.endLessonTime = this.getAvailableEndTime(
      <IStudyPeriod>this.dialogData.studyPeriod
    );
    this.availableTime = this.toHoursAndMinutes(
      this.dialogData.studyPeriod?.availableTime || 0
    );

    if (this.isStudentSelectorVisible) {
      this.students = this.dialogData.student?.length
        ? this.dialogData.student.sort((a, b) =>
            a.firstName.localeCompare(b.firstName)
          )
        : [];
      this.dialogForm.addControl(
        'student',
        new UntypedFormControl(this.students[0].id, Validators.required)
      );
    } else {
      this.userFullName = this.getUserName(this.dialogData);
    }
  }

  private toHoursAndMinutes(totalMinutes: number): string {
    const hours = Math.floor(totalMinutes / minutesInHour);
    const hoursPlural = this.getNumPlural(
      [$localize`час`, $localize`часа`, $localize`часов`],
      hours
    );
    const minutes = totalMinutes % minutesInHour;
    const minutersPlural = this.getNumPlural(
      [$localize`минута`, $localize`минута`, $localize`минут`],
      minutes
    );
    return `${hours} ${hoursPlural} ${minutes} ${minutersPlural}`;
  }

  private getUserName(data: ICalendarItem): string {
    const user = data.userRole === 'student' ? data.tutor : data.student[0];
    return `${user.firstName} ${user.lastName}`;
  }

  private getDefaultLessonDateTime(lesson?: ILesson): IRange<Date> {
    if (lesson) {
      const start = new Date(lesson.startsAt);
      const end = new Date(lesson.endsAt);

      return { start, end };
    }
    const start = new Date(this.dialogData.date);
    const studyPeriodEnd = new Date(
      (<IStudyPeriod>this.dialogData.studyPeriod).endsAt
    );
    const isLessThenHalfHour = +studyPeriodEnd - +start === msInHalfHour;
    const end = isLessThenHalfHour
      ? new Date(+start + msInHalfHour)
      : new Date(+start + defaultLessonDuration * msInMinute);

    return { start, end };
  }

  private setDefaultFormDate(): void {
    const toSelectStart = this.startLessonTime.find(
      item => +item === +this.defaultLessonTimeRange.start
    );
    const toSelectEnd = this.endLessonTime.find(
      item => +item === +this.defaultLessonTimeRange.end
    );
    const studyPeriodId = this.dialogData.studyPeriod?._id;
    const lessonId = this.dialogData.lesson ? this.dialogData.lesson._id : '';
    const studentId =
      this.dialogData.userRole === 'student' || this.isEditing
        ? this.dialogData.student[0].id
        : '';
    const comment = this.dialogData.lesson?.comment || '';
    this.dialogForm.addControl(
      'startsAt',
      new UntypedFormControl(toSelectStart, Validators.required)
    );
    this.dialogForm.addControl(
      'endsAt',
      new UntypedFormControl(toSelectEnd, Validators.required)
    );
    this.dialogForm.addControl(
      'studyPeriodId',
      new UntypedFormControl(studyPeriodId, Validators.required)
    );
    this.dialogForm.addControl(
      'lessonId',
      new UntypedFormControl(lessonId, Validators.required)
    );
    this.dialogForm.addControl(
      'studentId',
      new UntypedFormControl(studentId, Validators.required)
    );
    this.dialogForm.addControl(
      'isRepeat',
      new UntypedFormControl(false, Validators.required)
    );
    this.dialogForm.addControl('comment', new UntypedFormControl(comment));
    this.initialValue$.next(this.dialogForm.getRawValue());

    if (this.isRepeatCheckboxDisabled) {
      this.dialogForm.controls['isRepeat'].disable();
    }
  }

  private getAvailableStartTime(studyPeriod: IStudyPeriod): Date[] {
    let start = new Date(studyPeriod.startsAt);
    const end = new Date(studyPeriod.endsAt);
    const availableStartTime: Date[] = [];

    while (start < end) {
      availableStartTime.push(start);
      start = new Date(+start + msInHalfHour);
    }

    return availableStartTime;
  }

  private getAvailableEndTime(studyPeriod: IStudyPeriod): Date[] {
    const lessonStart: Date =
      this.dialogForm.get('startsAt')?.value ||
      this.defaultLessonTimeRange.start;
    let time = new Date(+lessonStart + msInHalfHour);
    const endTime = new Date(studyPeriod.endsAt);
    const msInmaxLessonDuration = studyPeriod.maxLessonDuration * msInMinute;
    const maxDate = new Date(+time + msInmaxLessonDuration);
    const timeArr: Date[] = [];

    while (time <= endTime && time <= maxDate) {
      timeArr.push(time);
      time = new Date(+time + msInHalfHour);
    }

    return timeArr;
  }

  public onStartLessonSelected(): void {
    this.endLessonTime = [];
    this.dialogForm.markAllAsTouched();
    this.dialogForm.get('endsAt')?.setValue(null);
    this.endLessonTime = this.getAvailableEndTime(
      <IStudyPeriod>this.dialogData.studyPeriod
    );
  }

  public getLessonClass(classPrefix: string): string {
    return this.dialogData.lesson
      ? `${classPrefix}${this.dialogData.lesson.status}`
      : '';
  }

  public get endControlHasError(): boolean {
    const endControl = <UntypedFormControl>this.dialogForm.get('endsAt');
    return endControl.hasError('required') || endControl.invalid;
  }

  public get studentControlHasError(): boolean {
    const studentControl = <UntypedFormControl>this.dialogForm.get('studentId');
    return studentControl.hasError('required') || studentControl.invalid;
  }

  public get isStudentSelectorVisible(): boolean {
    return !this.isEditing && this.dialogData.userRole === 'tutor';
  }

  public getNumPlural(options: string[], value: number): string {
    if (value % 10 === 1 && value % 100 !== 11) {
      return options[0];
    }
    if (
      value % 10 >= 2 &&
      value % 10 <= 4 &&
      (value % 100 < 10 || value % 100 >= 20)
    ) {
      return options[1];
    }
    return options[2];
  }

  public onButtonClick(confirmed: boolean): void {
    if (confirmed) {
      this.dialogRef.close(this.dialogForm.value);
      return;
    }
    this.dialogRef.close();
  }

  public handleTextareaInput(): void {
    if (this.dialogData.userRole !== 'tutor') {
      this.textarea.nativeElement.value = this.dialogData.lesson?.comment || '';
      this.dialogForm.controls['comment'].setValue(
        this.textarea.nativeElement.value
      );
    }
    this.autoResize();
  }

  private autoResize(): void {
    this.textarea.nativeElement.style.height = '5px';
    this.textarea.nativeElement.style.height = `${this.textarea.nativeElement.scrollHeight}px`;
  }

  public get isTutor(): boolean {
    return this.dialogData.userRole === 'tutor';
  }
}
