<mat-form-field class="input payment-choice">
  <mat-select
    [disabled]="disabled"
    (valueChange)="onPaymentMethodSelect($event)"
    [value]="selectedOptionObj.value"
  >
    <mat-select-trigger>
      <span class="select-trigger">
        <img [src]="selectedOptionObj.image" [alt]="selectedOptionObj.value" />
      </span>
    </mat-select-trigger>
    <mat-option *ngFor="let option of options" [value]="option.value">
      <div class="option-wrapper">
        <img [src]="option.image" [alt]="option.value" /> {{ option.text }}
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
