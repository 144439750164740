import { IStudentUser, ITutorUser, IUserBase } from "./user.interface";

export interface ILessonResponse {
  approved: ILessonStatusDetails;
  booked: ILessonStatusDetails;
  canceled: ILessonStatusDetails;
  completed: ILessonStatusDetails;
  freeCompleted: ILessonStatusDetails;
  pendingPayment: ILessonStatusDetails;
  lessonsList: ILesson[];
  numberOfLessons: number;
  status: string;
}

export interface ILessonStatusDetails {
  numberOfLessons: number;
  totalTutorsFee: number;
}

export interface ILesson {
  isPeriodLock?: boolean;
  createdAt: string;
  createdBy: string;
  date: string;
  endsAt: string;
  lessonCost: number;
  repeat: string;
  startsAt: string;
  status: LessonStatus;
  student: IStudentUser;
  studyPeriod: string;
  tutor: ITutorUser;
  tutorData?: IUserBase;
  updatedAt: string;
  tutorFee: number;
  comment: string;
  _id: string;
}

export enum LessonStatus {
  Approved = 'approved',
  Canceled = 'canceled',
  Completed = 'completed',
  FreeCompleted = 'free completed',
  Booked = 'booked',
  PendingPayment = 'pending payment'
}

export interface IUpdateLessonResponse {
  status: string;
  message: string;
  updatedLesson: {
    _id: string;
    student: string;
    tutor: string;
    studyPeriod: string;
    lessonCost: number;
    tutorFee: number;
    date: string;
    startsAt: string;
    endsAt: string;
    status: string;
    repeat: string;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    __v: 0
  }
}
