import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserTab, userTabs } from 'src/app/dashboard/constants/user.tabs';
import { genderi18n } from 'src/app/i18n/gender.i18n';
import {
  languageI18n,
  skillLevelI18n,
  teachingI18n,
} from 'src/app/i18n/study.i18n';
import { CountryOption } from 'src/interfaces/option.interface';
import { IStudentUser, ITutorUser } from 'src/interfaces/user.interface';
import countries from '../../constants/country.json';
import { PhoneMaskPipe } from '../../pipes/phone-mask.pipe';
import { ThemeService } from '../../services/theme.service';
import { AuthService } from '../../services/auth.service';
import { defaultTutorFeePerHour } from 'src/app/constants/user.constants';

export interface IDescription {
  title: string;
  value: string;
  icon?: string;
}

@Component({
  selector: 'app-associated-user-modal-box',
  templateUrl: 'associated-user-modal-box.component.html',
  styleUrls: ['associated-user-modal-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PhoneMaskPipe],
})
export class AssociatedUserModalBoxComponent {
  public readonly tabs = userTabs(this.user.role, 'associated');
  public activeTab: UserTab = UserTab.Profile;
  public isDarkTheme$ = this.themeService.isDarkTheme$;

  constructor(
    private phoneMask: PhoneMaskPipe,
    @Inject(MAT_DIALOG_DATA) public user: ITutorUser | IStudentUser,
    private themeService: ThemeService,
    private authService: AuthService
  ) {}

  public get name(): IDescription {
    return {
      title:
        this.user.role === 'student'
          ? $localize`Студент`
          : $localize`Преподаватель`,
      value: `${this.user.firstName}\n ${this.user.lastName}`,
    };
  }

  public get gender(): IDescription {
    return {
      title: $localize`Пол`,
      value: genderi18n[this.user.gender] || '-',
    };
  }

  public get locality(): IDescription {
    return {
      title: $localize`Город проживания`,
      value: this.user.locality || '-',
    };
  }

  public get country(): IDescription {
    const country = countries.find(
      country => country.code === this.user.country
    ) as CountryOption;
    return {
      title: $localize`Страна проживания`,
      value: country.name,
      icon: country.image,
    };
  }

  public get tel(): IDescription {
    return {
      title: $localize`Телефон`,
      value:
        this.user.contacts && this.user.contacts.phone
          ? <string>(
              this.phoneMask.transform(
                this.user.contacts.phone,
                this.user.phoneCountry
              )
            )
          : '-',
    };
  }

  public get email(): IDescription {
    return { title: $localize`Email`, value: this.user.email || '-' };
  }

  public get telegram(): IDescription {
    return {
      title: $localize`Телеграм`,
      value:
        this.user.contacts && this.user.contacts.telegram
          ? this.user.contacts.telegram
          : '-',
    };
  }

  public get meetingUrl(): IDescription {
    return {
      title: $localize`Ссылка на видеовстречу`,
      value:
        this.user.role === 'tutor' && this.user.meetingUrl
          ? this.user.meetingUrl
          : '-',
    };
  }

  public get techStack(): IDescription {
    return {
      title:
        this.user.role === 'student'
          ? $localize`Изучает`
          : $localize`Что преподает`,
      value: this.user.techStack
        ? this.user.techStack.map(item => teachingI18n[item]).join(', ')
        : '-',
    };
  }

  public get skillLevel(): IDescription {
    return {
      title: $localize`Уровень навыков`,
      value: skillLevelI18n[this.user.skillLevel] || '-',
    };
  }

  public get language(): IDescription {
    return {
      title:
        this.user.role === 'student'
          ? $localize`Предпочтение к языку`
          : $localize`Язык преподавания`,
      value: this.user.language
        ? this.user.language.map(item => languageI18n[item]).join(', ')
        : '-',
    };
  }

  public get about(): IDescription {
    return { title: $localize`О себе:`, value: this.user.about || '-' };
  }

  public get hobby(): IDescription {
    return { title: $localize`Хобби`, value: this.user.hobby || '-' };
  }

  public getTutorFee(student: IStudentUser): number {
    const tutorId = this.authService.user?.id;
    return (
      student.lessonPrices.find(
        lessonPriceData => lessonPriceData.tutorId === tutorId
      )?.tutorFeePerHour || defaultTutorFeePerHour
    );
  }
}
