import {
  Component,
  Input,
  EventEmitter,
  Output,
  Self,
  Optional,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import {
  UntypedFormControl,
  NgControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { removeAnySymbolsPattern } from 'src/app/constants/regexPattern.constants';
import { Country } from 'src/interfaces/country.interface';
import countries from '../../constants/country.json';
import { DestroyService } from '../../services/destroy.service';

function phoneMask(mask: string, prefix: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    if (!formGroup.value) {
      return null;
    }

    const lengthPhone =
      prefix.length + mask.replace(removeAnySymbolsPattern, '').length;
    return formGroup.value.length === lengthPhone
      ? null
      : { phoneMaskInvalid: true };
  };
}

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [DestroyService],
})
export class InputComponent implements OnChanges, OnInit {
  @Input() public formControl!: UntypedFormControl;
  @Input() public label = '';
  @Input() public placeholder = '';
  @Input() public withResetButton = false;
  @Input() public withHideButton = false;
  @Input() public iconPath = '';
  @Input() public maxLength = '';
  @Input() public isDisabled = false;
  @Output() public enterKeyup: EventEmitter<void> = new EventEmitter();
  @Output() public clearField: EventEmitter<void> = new EventEmitter();
  @Input() public mask = '';
  @Input() public prefix = '';
  @Input() public country!: Country | null;
  @Input() public type = '';
  @Input() public startWith = ''; // TODO What is difference between prefix & startWith?
  @Input() public emitClearFieldOnBackspace = true;

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private readonly destroy$: DestroyService
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = {
        writeValue(): void {},
        registerOnChange(): void {},
        registerOnTouched(): void {},
      };
    }
  }

  public ngOnInit(): void {
    if (this.country) {
      const selectedOptionObj = countries.find(
        country => country.code === this.country
      );
      this.prefix = selectedOptionObj?.prefix || '';
      this.mask = selectedOptionObj?.mask || '';
      this.formControl.addValidators(phoneMask(this.mask, this.prefix));

      this.formControl.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((value: string) => {
          if (value && !value.includes('+')) {
            this.formControl.setValue(`${this.prefix}${value}`);
          }
        });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.country && !changes.country?.firstChange) {
      this.formControl.setValue('+');
    }
    if (
      changes.country?.currentValue &&
      changes.country?.currentValue !== changes.country?.previousValue
    ) {
      const selectedOptionObj = countries.find(
        country => country.code === this.country
      );
      this.mask = selectedOptionObj?.mask || '';
      this.prefix = selectedOptionObj?.prefix || '';
      this.formControl.setValidators(phoneMask(this.mask, this.prefix));
    }
  }

  public get isRequired(): boolean {
    return this.formControl.hasValidator(Validators.required);
  }

  public onClearField(): void {
    if (this.country) {
      this.formControl.reset('');
      this.formControl.markAsDirty();
      return;
    }
    this.clearField.emit();
  }

  public onEnterKeyup(): void {
    this.enterKeyup.emit();
  }

  public onBackspaceKeyup(): void {
    if (!this.formControl.value && this.emitClearFieldOnBackspace) {
      this.clearField.emit();
    }
  }
}
