import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-month',
  templateUrl: './month.component.html',
  styleUrls: ['./month.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthComponent {
  @Input() public date!: Date;
  @Input() public type: 'month' | 'day' = 'month';
  @Input() public withCalendar = false;
  @Output() public onChangeDate: EventEmitter<Date> = new EventEmitter<Date>();

  public isCalendarOpen = false;

  constructor(private languageService: LanguageService) {}

  public get language(): string {
    return this.languageService.language;
  }

  public setNewDate(direction: 'next' | 'previous'): void {
    const nextDate =
      this.type === 'month'
        ? this.getNewMonth(direction)
        : this.getNewDay(direction);

    this.onChangeDate.emit(nextDate);
  }

  public getNewMonth(direction: 'next' | 'previous'): Date {
    const newDate = new Date(this.date);
    const month =
      direction === 'next'
        ? this.date.getMonth() + 1
        : this.date.getMonth() - 1;
    return new Date(newDate.setMonth(month));
  }

  public getNewDay(direction: 'next' | 'previous'): Date {
    const newDate = new Date(+this.date);

    direction === 'next'
      ? newDate.setDate(newDate.getDate() + 1)
      : newDate.setDate(newDate.getDate() - 1);

    return newDate;
  }

  public openCalendar(): void {
    if (this.withCalendar) {
      this.isCalendarOpen = true;
    }
  }

  public closeCaledar(): void {
    this.isCalendarOpen = false;
  }

  public onSelectedDate(date: Date): void {
    this.closeCaledar();
    this.onChangeDate.emit(date);
  }
}
