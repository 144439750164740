<div class="item" [class.flag-only]="isFlagOnly">
  <mat-form-field [class.input-required]="isRequired" class="input input-valid" >
    <mat-label *ngIf="!isFlagOnly" i18n>Страна проживания</mat-label>
    <mat-select (valueChange)="onCountrySelect($event)" [value]="selectedOptionObj.code" >
      <mat-select-trigger>
        <span class="select-trigger">
          <img [src]="selectedOptionObj.image" [alt]="selectedOptionObj.name" />
          <span *ngIf="!isFlagOnly">{{selectedOptionObj.name}}</span>
        </span>
      </mat-select-trigger>
        <mat-option *ngFor="let option of options" [value]="option.code" >
          <div class="option-wrapper">
            <img [src]="option.image" [alt]="option.name" /> {{ option.name }}
          </div>
        </mat-option>
    </mat-select>
  </mat-form-field>
</div>
