import { Component, HostBinding, Output, Input, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IUser } from 'src/interfaces/user.interface';

@Component({
	selector: 'notification-window',
	templateUrl: './notification-window.component.html',
	styleUrls: ['./notification-window.component.scss'],
})
export class NotificationWindowComponent {
	@Input() public notificationFormGroup!: UntypedFormGroup;

	@Input() public user!: IUser;

	@HostBinding('class.active') @Input() public isOpen = false;

	@Output() public isOpenChange = new EventEmitter<boolean>();

	public openNotification(): void {
		this.isOpen = !this.isOpen;
		this.isOpenChange.emit(this.isOpen);
	}
}
