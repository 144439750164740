import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { lessonStatusI18n } from 'src/app/i18n/lessonStatus.I18n';
import { IRange } from 'src/interfaces/calendar.interface';

import { IConfirmPopUp } from 'src/interfaces/confirm-pop-up.interface';
import { ILesson, LessonStatus } from 'src/interfaces/lesson.interface';
import { IStudyPeriod } from 'src/interfaces/study-period.interface';
import { IUser, UserRole } from 'src/interfaces/user.interface';

@Component({
  selector: 'app-confirm-pop-up',
  templateUrl: './confirm-pop-up.component.html',
  styleUrls: ['./confirm-pop-up.component.scss'],
})
export class ConfirmPopUpComponent implements OnInit, AfterViewInit {
  public timeRange!: IRange<Date>;
  public readonly lessonStatusI18n = lessonStatusI18n;
  public isStatusVisible = false;
  public lessonStatus!: LessonStatus;
  public userFullName = '';
  public isCheckboxVisible = false;
  public checkboxLableText = '';
  public isTooltipVisible = false;
  public form = new FormGroup({
    start: this.fb.control({
      value: this.timeRange?.start || '',
      disabled: true,
    }),
    end: this.fb.control({ value: this.timeRange?.end || '', disabled: true }),
  });
  @ViewChild('textArea') public textarea!: ElementRef<HTMLTextAreaElement>;

  constructor(
    private dialogRef: MatDialogRef<ConfirmPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IConfirmPopUp,
    private fb: FormBuilder
  ) {}

  public ngAfterViewInit(): void {
    if (this.dialogData.calendarItem?.lesson) {
      this.textarea.nativeElement.value =
        this.dialogData.calendarItem.lesson.comment;
      this.autoResize();
    }
  }

  public ngOnInit(): void {
    if (this.dialogData.calendarItem && this.dialogData.type) {
      if (this.dialogData.type === 'lesson') {
        this.getLessonData(
          <ILesson>this.dialogData.calendarItem.lesson,
          this.dialogData.calendarItem.userRole
        );
      } else if (this.dialogData.type === 'studyPeriod') {
        this.studyPeriodData(
          <IStudyPeriod>this.dialogData.calendarItem.studyPeriod,
          this.dialogData.calendarItem.tutor
        );
      } else if (this.dialogData.type === 'blockedPeriod') {
        this.blockedPeriodData(
          <ILesson>this.dialogData.calendarItem.blockedTime
        );
      }
    }
  }

  public get selectLabel(): string {
    switch (this.dialogData.type) {
      case 'lesson':
        return $localize`урока`;
      case 'studyPeriod':
        return $localize`периода`;
      case 'blockedPeriod':
        return $localize`блокирования`;
      default:
        return '';
    }
  }

  private getLessonData(lesson: ILesson, role: UserRole): void {
    this.isStatusVisible = true;
    this.isCheckboxVisible = lesson.repeat === 'every week';
    this.checkboxLableText = $localize`Повторять еженедельно`;
    this.lessonStatus = lesson.status;
    this.userFullName =
      role === 'student'
        ? this.getFullName(lesson.tutor)
        : this.getFullName(lesson.student);
    this.timeRange = this.getTimeRange(lesson);
    this.isTooltipVisible =
      role === 'tutor'
        ? Number(
            this.dialogData.calendarItem?.student.find(
              student =>
                student.id === this.dialogData.calendarItem?.lesson?.student.id
            )?.balance
          ) < 0
        : false;
  }

  private studyPeriodData(studyPeriod: IStudyPeriod, user: IUser): void {
    this.timeRange = this.getTimeRange(studyPeriod);
    this.isCheckboxVisible = studyPeriod.repeat === 'every week';
    this.checkboxLableText = $localize`Применить ко всей серии учебных периодов`;
    this.userFullName = this.getFullName(user);
  }

  private blockedPeriodData(lesson: ILesson): void {
    this.timeRange = this.getTimeRange(lesson);
  }

  private getTimeRange(item: ILesson | IStudyPeriod): IRange<Date> {
    return {
      start: new Date(item.startsAt),
      end: new Date(item.endsAt),
    };
  }

  private getFullName(user: IUser): string {
    return `${user.firstName} ${user.lastName}`;
  }

  public onButtonClick(isConfirmed: boolean): void {
    if (isConfirmed) {
      this.dialogRef.close(this.dialogData);
      return;
    }
    this.dialogRef.close();
  }

  private autoResize(): void {
    this.textarea.nativeElement.style.height = '5px';
    this.textarea.nativeElement.style.height = `${this.textarea.nativeElement.scrollHeight}px`;
  }
}
