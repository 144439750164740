<mat-form-field
  class="input"
  [class.input-required]="isRequired"
  [formGroup]="formGroup"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select
    [formControlName]="controlName"
    [disabled]="disabled"
    [placeholder]="placeholderText"
    [multiple]="withChips"
  >
    <mat-select-trigger *ngIf="withChips">
      <div class="chip">
        <mat-chip-list>
          <mat-chip
            *ngFor="let value of formGroup.get(controlName)?.value"
            [removable]="true"
            (removed)="removeChip(value)"
            selected
          >
            {{ getChipText(value) }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </mat-select-trigger>
    <mat-option
      *ngFor="let option of optionsList; let i = index"
      [attr.data-test]="'optionsTest' + i"
      [value]="option.value"
    >
      {{ option.text }}
    </mat-option>
  </mat-select>
  <button
    mat-button
    *ngIf="
      withResetButton &&
      formGroup.controls[controlName].value &&
      !formGroup.controls[controlName].disabled
    "
    matSuffix
    mat-icon-button
    (click)="onClearClick($event)"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-error><ng-content select="[error]"></ng-content></mat-error>
</mat-form-field>
