<div class="info">
  <span class="info__text" i18n>Дата:</span>
  <span *ngIf="!hasDateRange" class="info__text">
    {{ date.start | date: 'shortDate' }}
  </span>
  <span *ngIf="hasDateRange" class="info__text">
    {{ date.start | date: 'shortDate' }} — {{ date.end | date: 'shortDate' }}
  </span>
</div>
<div class="info d-md-none" *ngIf="!hideTime">
  <span class="info__text">{{ timeTitle + ':' }}</span>
  <span *ngIf="hasTime" class="info__text">
    {{ date.start | date: 'HH:mm' }} — {{ date.end | date: 'HH:mm' }}
  </span>
  <span *ngIf="!hasTime" class="data__text_empty"> -:- — -:- </span>
</div>
<div
  class="info"
  *ngIf="isStatusVisible"
  [class.dark-theme]="isDarkTheme$ | async"
>
  <span class="info__text info__text_label" i18n>Статус:</span>
  <span
    *ngIf="hasStatus"
    class="info__text info__text_status"
    [ngClass]="'info__text_' + statusClass"
  >
    {{ status }}
  </span>
  <span *ngIf="!hasStatus" class="data__text_empty"> -:- — -:- </span>
</div>
