import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { removeAnySymbolsPattern } from 'src/app/constants/regexPattern.constants';
import { Country } from 'src/interfaces/country.interface';
import countries from '../../constants/country.json';
import { ERROR } from 'src/app/constants/labels';

@Component({
  selector: 'phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PhoneInputComponent,
      multi: true,
    },
  ]
})
export class PhoneInputComponent
  implements ControlValueAccessor, OnChanges, OnInit
{
  @Input() public country!: Country;
  @Input() public isValid = true;
  @Input() public phoneErrorMatcher!: ErrorStateMatcher;
  @Input() public isRequired = false;

  public value!: string;
  public mask!: string;
  public prefix!: string;
  public lengthPhone = 1;

  public ERROR = ERROR;

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if(!changes.country?.currentValue){
      return;
    }
    const selectedOptionObj = countries.find((country) => country.code === this.country);

    // ngx mask has a subscription on changes of the mask (or prefix) and value that are executed randomly. 
    // Because of this randomness, value subscription can be executed first and then the mask (or prefix) subscription.
    // with this order value won't be cleared in the input.
    // Therefore, with the help of detectChanges, we guarantee that the subscription of the mask (or prefix) is executed first, 
    // and then the value subscription, in this order there will be no situations where the value remains uncleared in the mask
    this.mask = selectedOptionObj?.mask ? selectedOptionObj?.mask : '';
    this.prefix = selectedOptionObj?.prefix ? selectedOptionObj?.prefix : '';
    this.lengthPhone = this.prefix.slice(1).length + this.mask.replace(removeAnySymbolsPattern, '').length;
    this.changeDetector.detectChanges(); // Should be after mask and prefix but before value assignment

    this.value = '';
    this.onTouch();
    this.onChange(this.value);
  }

  public ngOnInit(): void {
    this.lengthPhone = this.prefix.slice(1).length + this.mask.replace(removeAnySymbolsPattern, '').length;
    if (!this.country) {
      throw new Error(
        '[PhoneInputComponent]: expected country to be present. Please add country binding in your component in html <phone-input [country]="phoneCountry.value" formControlName="phone"></phone-input>'
      );
    }
  }

  private onChange = (value: string): void => {};
  private onTouch = () => {};

  public writeValue(value: string): void {
    this.value = value?.slice(this.prefix.length - 1);
  }
  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public onInputChange(value: string): void {
    this.value = value;
    this.onTouch();
    this.onChange(value ? this.prefix.slice(1) + value : '');
  }

  public clearPhone(): void {
    this.value = '';
    this.onTouch();
    this.onChange(this.value);
  }
}
