import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { IMessage } from 'src/interfaces/messages.interface';
import { DestroyService } from '../../services/destroy.service';
import { NotificationService } from './services/notification.service';
import { ThemeService } from '../../services/theme.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [DestroyService],
})
export class NotificationsComponent implements OnInit {
  public messages: IMessage[] = [];
  public isBellOpen = false;

  @ViewChild('notificationsBox') messageBox!: ElementRef;

  @HostListener('document:click', ['$event'])
  public clickOut(event: Event): void {
    if (
      this.messageBox &&
      !this.elementRef.nativeElement.contains(event.target) &&
      !this.messageBox.nativeElement.contains(event.target)
    ) {
      this.isBellOpen = false;
    }
  }

  public isDarkTheme$!: Observable<boolean>;

  constructor(
    public notificationService: NotificationService,
    private destroy$: DestroyService,
    private elementRef: ElementRef,
    private themeService: ThemeService
  ) {}

  public ngOnInit(): void {
    this.notificationService
      .initMessages()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
    this.notificationService.messages$
      .pipe(takeUntil(this.destroy$))
      .subscribe(messages => {
        this.messages = messages;
      });
    this.isDarkTheme$ = this.themeService.isDarkTheme$;
  }

  public deleteAllMessages(): void {
    this.messages.forEach(message => {
      if (this.notificationService.canDeleteMessage(message)) {
        this.notificationService
          .deleteMessage(message)
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      }
    });
  }

  public closeNotificationPanel(): void {
    this.isBellOpen = false;
  }
}
