import { Injectable } from '@angular/core';
import { Option } from 'src/interfaces/option.interface';

export const allKey = 'all';

@Injectable({
  providedIn: 'root',
})
export class OptionService {
  public static transform<Value extends string | number = string | number>(
    obj: Record<Value, string>
  ): Array<Option<Value>> {
    return Object.entries(obj).map(([value, text]) => {
      const _value = !Number.isNaN(Number(value)) ? Number(value) : value;

      return { value: _value as Value, text: text as string };
    });
  }

  public static withAllOption<T extends Option>(source: T[], text = 'Все'): T[]{
    return [{value: allKey, text: text} as T, ...source]
  }
}
