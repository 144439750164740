import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ERROR } from 'src/app/constants/labels';
import { PayoutStrategyComponent } from '../../strategies/payout.strategy';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackBarService } from '../../modules/snack-bar/services/snack-bar.service';

@Component({
  selector: 'app-payout-paypal-form',
  templateUrl: './payout-paypal-form.component.html',
  host: { class: 'payout-form' },
})
export class PayoutPaypalFormComponent implements PayoutStrategyComponent {
  @Input() public formGroup!: UntypedFormGroup;
  @Input() public isReadonly = false;
  @Input() public isCopy = false;

  public ERROR = ERROR;

  constructor(
    private clipboard: Clipboard,
    private snackBarService: SnackBarService
  ) {}

  public ngOnInit(): void {
    if (this.isReadonly) {
      this.formGroup.get('email')?.disable();
    }
  }

  public onCopyCard(): void {
    this.clipboard.copy(this.formGroup.get('email')?.value);
    this.snackBarService.success($localize`Email скопирован!`);
  }
}
