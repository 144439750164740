import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Tab } from 'src/interfaces/tab.interface';

@Component({
	selector: 'app-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent<T extends string | number = string | number> implements OnInit {
	@Input() public tabs: Tab<T>[] = [];
	@Input() public activeTab!: T;
	@Output() public activeTabChange = new EventEmitter<T>();

	public ngOnInit(): void {
		if (!this.activeTab) {
			this.activeTab = this.tabs.find((tab) => !tab.disabled)?.key as T;
			this.activeTabChange.emit(this.activeTab);
		}
	}

	public onTabClick(key: T): void {
		this.activeTab = key;
		this.activeTabChange.emit(this.activeTab);
	}
}
