<div class="wrapper">
  <div class="img-wrapper">
    <img
      class="user-photo"
      [src]="
        user?.avatarPaths ? user.avatarPaths?.mini : 'assets/icons/profile.svg'
      "
      alt="User avatar"
    />
  </div>
  <div class="user-info">
    <p class="title">{{ roleI18n[user.role] }}</p>
    <p class="user-name">{{ user.firstName }}<br />{{ user.lastName }}</p>
    <p class="user-phone">
      {{
        user.contacts && user.contacts.telegram
          ? user.contacts.telegram
          : user.contacts && user.contacts.phone
          ? (user.contacts.phone | phoneMask: user.phoneCountry)
          : '-'
      }}
    </p>
    <p class="user-email">{{ user.email }}</p>
    <p class="user-price" *ngIf="user.role === 'student' && price" i18n>
      За урок - {{ price }} грн\час
    </p>
  </div>
  <div class="user-info">
    <p class="title" i18n>Пол</p>
    <p class="user-gender">
      {{ genderI18n[user.gender] ? genderI18n[user.gender] : '-' }}
    </p>
    <p class="title" i18n>Город проживания</p>
    <p class="user-locality">{{ user.locality ? user.locality : '-' }}</p>
    <p class="title" i18n>Страна проживания</p>
    <div class="user-country-wrapper">
      <img
        class="user-country-flag"
        [src]="
          selectedOptionObj?.image
            ? selectedOptionObj.image
            : 'assets/icons/profile.svg'
        "
        [alt]="selectedOptionObj?.name"
      />
      <p class="user-country">
        {{ selectedOptionObj?.name ? selectedOptionObj.name : '-' }}
      </p>
    </div>
  </div>
</div>
