import { Component, EventEmitter, Output, Input } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  @Input() public date!: Date;
  @Output() public onChangeDate: EventEmitter<Date> = new EventEmitter();

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private languageService: LanguageService
  ) {
    this.dateAdapter.setLocale(this.languageService.language);
  }

  public onSelectedChange(date: Date): void {
    this.date = date;
    this.onChangeDate.emit(date);
  }
}
