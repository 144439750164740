import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import {
  ILoginResponse,
  IUserResponce,
  IUserUpdateResponce,
  Login,
  Register,
  SendVerification,
} from 'src/interfaces/auth.interface';

import {
  loginUrl,
  regUrl,
  forgotPasswordUrl,
  logoutUrl,
  refreshToken,
  sendEmailUrl,
  userLocaleUrl,
  getUserByIdUrl,
} from 'src/app/endpoints/endpoints';

import { Router } from '@angular/router';

import { userUrl } from 'src/app/endpoints/endpoints';
import { IResponseUser, IUser, Locale } from 'src/interfaces/user.interface';
import { NOTIFY_ERROR } from 'src/app/interceptors';
import { IGNORE_REFRESH_TOKEN } from 'src/app/interceptors/jwt/jwt.interceptor';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user$ = new BehaviorSubject<IUser | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  public get user(): IUser | null {
    return this.user$.value;
  }

  register(data: Register): Observable<Object> {
    return this.http.post(regUrl, data);
  }

  sendEmailVerification(data: SendVerification): Observable<Object> {
    return this.http.post(sendEmailUrl, data);
  }

  login(data: Login): Observable<IUser> {
    return this.http
      .post<ILoginResponse>(loginUrl, data, {
        context: new HttpContext().set(IGNORE_REFRESH_TOKEN, true),
      })
      .pipe(
        map(({ loggedUser }) => loggedUser),
        tap(user => this.user$.next(user))
      );
  }

  getUser(): Observable<IUser> {
    return this.http
      .get<IUserResponce>(`${userUrl}/me`, {
        context: new HttpContext().set(NOTIFY_ERROR, false),
      })
      .pipe(
        map(({ currentUser }) => currentUser),
        tap(currentUser => {
          this.user$.next(currentUser);
        }),
        catchError(err => {
          const isAuthUrl =
            window.location.href.includes('/signup') ||
            window.location.href.includes('/forgot-password');

          if (err instanceof HttpErrorResponse) {
            this.user$.next(null);
            !isAuthUrl && this.router.navigate(['']);
            return of(this.user as unknown as IUser);
          }

          return throwError(err);
        })
      );
  }

  getUserRequest(userId: string): Observable<IResponseUser> {
    return this.http.get<IResponseUser>(getUserByIdUrl(userId));
  }

  updateUser(userData: Partial<IUser>): Observable<IUser> {
    return this.http.patch<IUserUpdateResponce>(`${userUrl}/me`, userData).pipe(
      map(({ updatedUser }) => updatedUser),
      tap(user => this.user$.next(user))
    );
  }

  updateUserLocale(locale: Locale): Observable<Object> {
    return this.http.patch<IUserUpdateResponce>(userLocaleUrl, { locale });
  }

  forgotPassword(data: Pick<Login, 'email'>): Observable<Object> {
    return this.http.post(forgotPasswordUrl, data, {
      context: new HttpContext().set(IGNORE_REFRESH_TOKEN, true),
    });
  }

  isAuthenticated(): boolean {
    return !!this.user;
  }

  hasAccess(): boolean {
    const isVerified = !!this.user?.verifiedRole;

    if (isVerified && this.user?.role === 'student') {
      return !!this.user.tutors?.length;
    }

    return isVerified;
  }

  logout(): Observable<unknown> {
    return this.logoutApi().pipe(
      tap(() => {
        window.location.href = '';
      })
    );
  }

  logoutApi(): Observable<unknown> {
    this.user$.next(null);
    return this.http.get(logoutUrl, {
      context: new HttpContext().set(NOTIFY_ERROR, false),
    });
  }

  refreshToken(): Observable<unknown> {
    return this.http.get(refreshToken, {
      context: new HttpContext().set(NOTIFY_ERROR, false),
    });
  }
}
