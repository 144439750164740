import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ERROR } from 'src/app/constants/labels';

@Component({
  selector: 'app-amount-field',
  templateUrl: './amount-field.component.html',
  styleUrls: ['./amount-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AmountFieldComponent,
      multi: true,
    },
  ],
})
export class AmountFieldComponent implements ControlValueAccessor {
  @Input() public sumErrorMatcher!: ErrorStateMatcher;

  //Todo added dynamic errors text in future tasks
  @Input() public isShowError = true;

  @Input() public isMoreAvailable = false;
  @Input() public isLessHundred = false;
  @Input() public mask = '99999';
  @Input() public isShowLabel = true;
  @Input() public label = '';
  @Input() public disabled = false;

  public value!: number;
  public ERROR = ERROR;

  private onChange = (value: number): void => {
    value;
  };
  private onTouch = (): void => {};

  public writeValue(value: number): void {
    this.value = value;
  }

  public registerOnChange(fn: (value: Nullable<number>) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public onInputChange(value: number): void {
    this.value = value;
    this.onTouch();
    this.onChange(value);
  }
}
