import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { SocketEventsMap } from 'src/interfaces/socketIO.interface';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class SocketIOService {
	private socket!: Socket<Record<string, (data: unknown) => void>>;
	private init$ = new AsyncSubject<boolean>();

	public connectToServer(): void {
		const server = environment.server ? environment.server : 'https://www.student-app.net.ua/';
		this.socket = io(server, { withCredentials: true });
		this.socket.on('connect', () => {
			this.init$.next(true);
			this.init$.complete();
		});
		this.socket.on('connect_error', _ => {
			this.socket.close();
		});
	}

	public listen<Key extends keyof SocketEventsMap, Data = SocketEventsMap[Key]>(event: Key): Observable<Data> {
		return this.init$.pipe(
			switchMap(() => {
				return new Observable<Data>(subscriber => {
					this.socket.on(event as string, (data: unknown) => {
						subscriber.next(data as Data);
					});

					return () => {
						this.socket.off(event);
					};
				});
			})
		);
	}

	public diconnectServer(): void {
		if (this.socket) {
			this.socket.disconnect();
		}
	}
}
