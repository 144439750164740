import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IUser } from 'src/interfaces/user.interface';
import { AuthService } from '../../services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '../../services/destroy.service';
import { SnackBarService } from '../../modules/snack-bar/services/snack-bar.service';

@Component({
	selector: 'app-notification-window-modal',
	templateUrl: './notification-window-modal.component.html',
	styleUrls: ['./notification-window-modal.component.scss'],
	providers: [DestroyService],
})
export class NotificationWindowModalComponent {
	public readonly user = this.data.user;
	public readonly formGroup = new UntypedFormGroup({
		notificationClassesDestination: new UntypedFormControl(this.user.notificationClassesDestination),
	});

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { user: IUser },
		private auth: AuthService,
		private readonly destroy$: DestroyService,
		private snackBarService: SnackBarService
	) {}

	public onSubmit(): void {
		this.auth
			.updateUser(this.formGroup.value)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.snackBarService.success($localize `Данные обновлены успешно`);
			});
	}
}
