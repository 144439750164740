<modal size="sm" [result]="null">
  <modal-title i18n>Сортировка</modal-title>
  <modal-body>
    <app-label-container class="selects__item" labelText="Поле" i18n-labelText>
      <app-select
        [formGroup]="sortFormGroup"
        controlName="key"
        [optionsList]="fields"
      ></app-select>
    </app-label-container>
    <app-label-container
      class="selects__item"
      labelText="Порядок сортировки"
      i18n-labelText
    >
      <app-select
        [formGroup]="sortFormGroup"
        controlName="order"
        [optionsList]="order"
      ></app-select>
    </app-label-container>
  </modal-body>
  <modal-footer>
    <button
      class="stroked"
      [disabled]="isDefaultConfig"
      [matDialogClose]="dialogData.defaultConfig"
      i18n
    >
      Отменить сортировку
    </button>
    <button
      class="primary"
      [disabled]="isDefaultConfig"
      [matDialogClose]="sortFormGroup.value"
      i18n
    >
      Применить
    </button>
  </modal-footer>
</modal>
