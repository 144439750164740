import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Injectable()

export class LoaderInterceptor implements HttpInterceptor {

  private countReq: number = 0;

  constructor(public loaderService: LoaderService) { }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
   Promise.resolve().then(()=> this.loaderService.isLoading$.next(true));

    this.countReq++;

    return next.handle(req).pipe(
      finalize(() => {
        this.countReq--;
        if (this.countReq === 0) this.loaderService.isLoading$.next(false);
      }),
    );
  }
}