import { UserRole } from 'src/interfaces/user.interface';

export interface INavItem {
  visibility: (role: UserRole) => boolean;
  link: string;
  activeClass: string;
  iconPath: string;
  darkThemeIconPath: string;
  title: string;
  action?: (fn: any) => void;
  datatest: string;
}

export const navItems: INavItem[] = [
  {
    visibility: () => true,
    link: '/dashboard/user',
    activeClass: 'highlighted',
    iconPath: 'assets/icons/sidebar/profile.svg',
    darkThemeIconPath: 'assets/icons/sidebar/profile-dt.svg',
    title: $localize`Профиль`,
    datatest: 'userProfileIco',
  },
  {
    visibility: (role: UserRole) => role === 'admin',
    link: '/dashboard/admin',
    activeClass: 'highlighted',
    iconPath: 'assets/icons/sidebar/admin-panel.svg',
    darkThemeIconPath: 'assets/icons/sidebar/admin-panel-dt.svg',
    title: $localize`Пользователи`,
    datatest: 'userAdminPanelIco',
  },
  {
    visibility: (role: UserRole) => role !== 'admin',
    link: 'calendar',
    activeClass: 'highlighted',
    iconPath: 'assets/icons/sidebar/calendar.svg',
    darkThemeIconPath: 'assets/icons/sidebar/calendar-dt.svg',
    title: $localize`Календарь`,
    datatest: 'userCalendarIco',
  },
  {
    visibility: (role: UserRole) => role === 'tutor',
    link: '/dashboard/tutor-report',
    activeClass: 'highlighted',
    iconPath: 'assets/icons/sidebar/assignment.svg',
    darkThemeIconPath: 'assets/icons/sidebar/assignment-dt.svg',
    title: $localize`Отчёт`,
    datatest: 'userTutorReportIco',
  },
  {
    visibility: (role: UserRole) => role === 'student',
    link: 'payment',
    activeClass: 'highlighted',
    iconPath: 'assets/icons/sidebar/balance_wallet.svg',
    darkThemeIconPath: 'assets/icons/sidebar/balance_wallet-dt.svg',
    title: $localize`Кошелёк`,
    datatest: 'userPaymentIco',
  },
  {
    visibility: (role: UserRole) => role === 'admin',
    link: '/dashboard/admin-payout',
    activeClass: 'highlighted',
    iconPath: 'assets/icons/sidebar/admin-payout.svg',
    darkThemeIconPath: 'assets/icons/sidebar/admin-payout-dt.svg',
    title: $localize`Выплаты`,
    datatest: 'userPayoutIco',
  },
  {
    visibility: (role: UserRole) => role === 'admin',
    link: '/dashboard/admin-lessons',
    activeClass: 'highlighted',
    iconPath: 'assets/icons/sidebar/lesson.svg',
    darkThemeIconPath: 'assets/icons/sidebar/lesson-dt.svg',
    title: $localize`Все уроки`,
    datatest: 'userLessonIco',
  },
  {
    visibility: () => true,
    link: '/dashboard/contacts',
    activeClass: 'highlighted',
    iconPath: 'assets/icons/sidebar/contacts.svg',
    darkThemeIconPath: 'assets/icons/sidebar/contacts-dt.svg',
    title: $localize`Информация`,
    datatest: 'userTechHelpIco',
  },
  {
    visibility: () => true,
    link: '',
    activeClass: '',
    iconPath: 'assets/icons/sidebar/logout.svg',
    darkThemeIconPath: 'assets/icons/sidebar/logout-dt.svg',
    title: $localize`Выйти`,
    datatest: 'userExitIco',
    action: (f: () => void) => f(),
  },
];
