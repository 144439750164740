<mat-form-field class="input">
  <mat-label *ngIf="isShowLabel">{{ label }}</mat-label>
  <input
    matInput
    type="text"
    placeholder="Сумма"
    i18n-placeholder
    [ngModel]="value"
    [mask]="mask"
    (ngModelChange)="onInputChange($event)"
    [errorStateMatcher]="sumErrorMatcher"
    [disabled]="disabled"
    [specialCharacters]="[]"
  />
  <mat-error *ngIf="isMoreAvailable && !isLessHundred && isShowError">{{
    ERROR.moreAvailable
  }}</mat-error>
  <mat-error *ngIf="isLessHundred && isShowError">{{
    ERROR.lessHundredSum
  }}</mat-error>
</mat-form-field>
