<span class="title">{{ description.title }}</span>
<span *ngIf="valueType === 'text'" class="text">{{
  description.value ? description.value : '-'
}}</span>
<a
  *ngIf="valueType === 'link'"
  class="text"
  [href]="description.value"
  target="_blank"
  class="text text_url"
  >{{ description.value }}</a
>
<span *ngIf="valueType === 'iconed'" class="country-wrapper">
  <img [src]="description.icon" [alt]="description.value" />
  <span class="text">{{ description.value }}</span>
</span>
<ng-content></ng-content>
