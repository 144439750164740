<modal class="popup" size="sm" [class.withoutBody]="!dialogData.calendarItem">
  <modal-title>
    {{ dialogData.title }}
    <span
      *ngIf="isTooltipVisible"
      class="tooltip"
      tooltipDirection="right"
      [tooltip]="balanceTooltip"
    ></span>
    <ng-template #balanceTooltip>
      <div class="tooltip-text" i18n>У студента<br />недостаточно средств</div>
    </ng-template>
  </modal-title>
  <modal-body *ngIf="dialogData.calendarItem">
    <app-actions-info
      [date]="timeRange"
      [hasDateRange]="!lessonStatus && dialogData.type !== 'blockedPeriod'"
      [status]="lessonStatusI18n[lessonStatus]"
      [isStatusVisible]="isStatusVisible"
      [hasTime]="true"
      [statusClass]="lessonStatus"
    >
    </app-actions-info>
    <div *ngIf="lessonStatus" class="user-name">
      {{ userFullName }}
    </div>
    <div class="d-none d-md-flex time">
      <div class="time__item">
        <span class="time__title" i18n>Начало {{ selectLabel }}</span>
        <app-select
          [formGroup]="form"
          formControlName="start"
          *ngIf="dialogData.calendarItem"
          [disabled]="true"
          [placeholderText]="(timeRange.start | date: 'HH:mm') ?? ''"
        >
        </app-select>
      </div>
      <div class="time__item">
        <span class="time__title" i18n>Окончание {{ selectLabel }}</span>
        <app-select
          *ngIf="dialogData.calendarItem"
          [formGroup]="form"
          formControlName="end"
          [disabled]="true"
          [placeholderText]="(timeRange.end | date: 'HH:mm') ?? ''"
        >
        </app-select>
      </div>
    </div>
    <div class="checkbox" *ngIf="dialogData.type !== 'blockedPeriod'">
      <mat-checkbox
        color="primary"
        [(ngModel)]="dialogData.isRepeat"
        [disabled]="!isCheckboxVisible"
      >
        <mat-label
          class="checkbox-label"
          [class.checkbox-label_disabled]="!isCheckboxVisible"
          >{{ checkboxLableText }}</mat-label
        >
      </mat-checkbox>
    </div>
    <div class="comment" *ngIf="dialogData.type === 'lesson'">
      <div class="comment__title" i18n>Комментарий к уроку</div>
      <div class="comment__textarea-container">
        <textarea
          #textArea
          disabled
          class="comment__textarea"
          i18n-placeholder
          placeholder="Комментарий:"
        ></textarea>
      </div>
    </div>
  </modal-body>
  <modal-footer>
    <app-action-buttons
      [confirmButtonTitle]="dialogData.button"
      (buttonClick)="onButtonClick($event)"
    >
    </app-action-buttons>
  </modal-footer>
</modal>
