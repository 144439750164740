import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardMask'
})
export class CreditCardMaskPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    const cardNumber = value.replace(/[^0-9]/g, '');

    const maskedCardNumber = cardNumber.slice(0,4) + ' **** **** ' + cardNumber.slice(-4);
    
    return maskedCardNumber;
  }

}
