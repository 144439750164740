import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { BehaviorSubject } from 'rxjs';

import {
  availableTimeRange,
  breakDurationDefault,
  breakDurationRange,
  calendarHoursRange,
  maxLessonDurationDefault,
  maxLessonDurationRange,
} from 'src/app/constants/calendar.conctants';
import { placeholders } from 'src/app/constants/placeholders.constants';
import {
  halfOfHourInMinutes,
  minutesInHour,
  msInMinute,
} from 'src/app/constants/time.constants';
import { ICalendarItem, IRange } from 'src/interfaces/calendar.interface';
import { ISelectData } from 'src/interfaces/filters.interface';
import { ILesson } from 'src/interfaces/lesson.interface';
import { ITutorUser } from 'src/interfaces/user.interface';

interface IStudyPeriodForm {
  startsAt: number;
  tutorId: string;
  endsAt: number | '';
  breakDuration: number | '';
  maxLessonDuration: number | '';
  availableTime: number | '';
  studyPeriodId: string;
  isRepeat: boolean;
}
@Component({
  selector: 'app-actions-study-periods',
  templateUrl: './actions-study-periods.component.html',
  styleUrls: ['./actions-study-periods.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsStudyPeriodsComponent implements OnInit {
  public dialogForm: UntypedFormGroup = new UntypedFormGroup({
    startsAt: new UntypedFormControl('', Validators.required),
    tutorId: new UntypedFormControl('', Validators.required),
    endsAt: new UntypedFormControl('', Validators.required),
    breakDuration: new UntypedFormControl('', Validators.required),
    maxLessonDuration: new UntypedFormControl('', Validators.required),
    availableTime: new UntypedFormControl('', Validators.required),
    studyPeriodId: new UntypedFormControl('', Validators.required),
    isRepeat: new UntypedFormControl(false),
  });

  public periodStartTimeOptions: ISelectData[] = [];
  public periodEndTimeOptions: ISelectData[] = [];
  public maxLessonDurationOptions: ISelectData[] = [];
  public breakDurationOptions: ISelectData[] = [];
  public availableTimeOptions: ISelectData[] = [];

  public placeholders = placeholders;

  public readonly initialValue$ = new BehaviorSubject(
    this.dialogForm.getRawValue()
  );

  public readonly isDirty$ = dirtyCheck(this.dialogForm, this.initialValue$, {
    debounce: 0,
    useBeforeunloadEvent: false,
  });

  constructor(
    private dialogRef: MatDialogRef<ActionsStudyPeriodsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ICalendarItem
  ) {}

  public ngOnInit(): void {
    this.getDialogFormOptions();
    this.checkDialogFormChanges();
    this.setInitialValuesToDialogForm();
  }

  public get userName(): string {
    const user = this.dialogData.tutor;
    return `${user.firstName} ${user.lastName}`;
  }

  public get title(): string {
    return this.isEdit
      ? $localize`Редактирование учебного периода`
      : $localize`Создание учебного периода`;
  }

  public get isEdit(): boolean {
    return !!this.dialogData.studyPeriod;
  }

  public get isRepeatCheckboxDisabled(): boolean {
    return !!(
      this.dialogData.studyPeriod &&
      this.dialogData.studyPeriod.repeat !== 'every week'
    );
  }

  private get pureDate(): Date {
    return new Date(this.dialogData.date.toDateString());
  }

  public get periodTimeRange(): IRange<Date> {
    const start = new Date(
      this.dialogData.studyPeriod?.startsAt || this.dialogData.date
    );
    const end = new Date(
      this.dialogData.studyPeriod?.endsAt || this.dialogData.date
    );

    return { start, end };
  }

  public get endControlHasError(): boolean {
    const endControl = <UntypedFormControl>this.dialogForm.get('endsAt');
    return endControl.hasError('required') || endControl.invalid;
  }

  public onButtonClick(isConfirmed: boolean): void {
    let dialogResult;
    if (isConfirmed) {
      this.dialogForm.value.startsAt = new Date(
        this.pureDate.setMinutes(this.dialogForm.value.startsAt)
      );
      this.dialogForm.value.endsAt = new Date(
        this.pureDate.setMinutes(this.dialogForm.value.endsAt)
      );
      dialogResult = this.dialogForm.value;
    }
    this.dialogRef.close(dialogResult);
  }

  private getEndsAt(data: IStudyPeriodForm): number | '' {
    const difference = +data.endsAt - +data.startsAt;
    return difference <= 0 ? '' : data.endsAt;
  }

  private getAvailableTime(data: IStudyPeriodForm): number {
    const difference = +data.endsAt - +data.startsAt;
    const defaultAvailableTime = this.dialogData.availableTime || difference;
    return !data.availableTime ||
      data.availableTime > difference ||
      data.availableTime < 0
      ? defaultAvailableTime
      : data.availableTime;
  }

  private getMaxLessonDuration(data: IStudyPeriodForm): number {
    const availableTime = data.availableTime || +data.endsAt - +data.startsAt;
    const previousMaxLessonDuration = (<ITutorUser>this.dialogData.tutor)
      .studyPeriodDefaultMaxLessonDuration;
    return !data.maxLessonDuration ||
      data.maxLessonDuration > availableTime ||
      data.maxLessonDuration < 0
      ? previousMaxLessonDuration && previousMaxLessonDuration > availableTime
        ? maxLessonDurationDefault > availableTime
          ? availableTime
          : maxLessonDurationDefault
        : previousMaxLessonDuration
      : data.maxLessonDuration;
  }

  private getLongerLessonDuration(lessons?: ILesson[]): number {
    if (!lessons || !lessons.length) {
      return 0;
    }
    const lessonsDuration = lessons.map(
      lesson =>
        (+new Date(lesson.endsAt) - +new Date(lesson.startsAt)) / msInMinute
    );
    return Math.max(...lessonsDuration);
  }

  private getBreakDuration(data: IStudyPeriodForm): number {
    const availableTime = data.availableTime || +data.endsAt - +data.startsAt;
    const previousBreakDuration = (<ITutorUser>this.dialogData.tutor)
      .studyPeriodDefaultBreakDuration;
    return (typeof data.breakDuration !== 'number' && !data.breakDuration) ||
      data.breakDuration > availableTime
      ? previousBreakDuration > availableTime
        ? breakDurationDefault
        : previousBreakDuration
      : data.breakDuration;
  }

  private setInitialValuesToDialogForm(): void {
    this.dialogForm
      .get('startsAt')
      ?.setValue(this.convertToMinutes(this.periodTimeRange.start));
    this.dialogForm.get('tutorId')?.setValue(this.dialogData.tutor.id);

    if (this.dialogData.studyPeriod) {
      const { studyPeriod } = this.dialogData;
      this.dialogForm
        .get('endsAt')
        ?.setValue(this.convertToMinutes(this.periodTimeRange.end));
      this.dialogForm.get('breakDuration')?.setValue(studyPeriod.breakDuration);
      this.dialogForm
        .get('maxLessonDuration')
        ?.setValue(studyPeriod.maxLessonDuration);
      this.dialogForm
        .get('availableTime')
        ?.setValue(studyPeriod.initialAvailableTime);
      this.dialogForm.get('studyPeriodId')?.setValue(studyPeriod._id);
      this.dialogForm
        .get('isRepeat')
        ?.setValue(studyPeriod.repeat === 'every week');
      if (this.isRepeatCheckboxDisabled) {
        this.dialogForm.get('isRepeat')?.disable();
      }
    }
    this.initialValue$.next(this.dialogForm.getRawValue());
  }

  private convertToMinutes(date: Date): number {
    return date.getHours() * minutesInHour + date.getMinutes();
  }

  private getTimeArray(min: number, max: number): ISelectData[] {
    const durationOptions: ISelectData[] = [];
    const step = halfOfHourInMinutes;
    let minValue = min;

    while (minValue <= max) {
      const timeString = this.toHoursAndMinutes(minValue);
      durationOptions.push({ value: minValue, text: timeString });
      minValue += step;
    }

    return durationOptions;
  }

  private toHoursAndMinutes(totalMinutes: number): string {
    const hours = Math.floor(totalMinutes / minutesInHour);
    const minutes = totalMinutes % minutesInHour;
    return `${this.padToTwoDigits(hours)}:${this.padToTwoDigits(minutes)}`;
  }

  private padToTwoDigits(num: number): string {
    const maxLength = 2;
    return num.toString().padStart(maxLength, '0');
  }

  private getDialogFormOptions(data?: IStudyPeriodForm): void {
    const longerLessonDuration = this.getLongerLessonDuration(
      this.dialogData.lessonsBelongCurrentStudyPeriod
    );
    const maxLessonDurationMax =
      data && maxLessonDurationRange.max > data.availableTime
        ? data.availableTime || 0
        : maxLessonDurationRange.max;
    const breakDurationMax =
      data && breakDurationRange.max > data.availableTime
        ? data.availableTime || 0
        : breakDurationRange.max;
    const availableTimeMax =
      data && data.availableTime
        ? +data.endsAt - +data.startsAt
        : (+this.periodTimeRange.end - +this.periodTimeRange.start) /
          msInMinute;

    const previousStudyPeriod =
      this.dialogData.previousStudyPeriod &&
      new Date(this.dialogData.previousStudyPeriod.endsAt);
    const nextStudyPeriod =
      this.dialogData.nextStudyPeriod &&
      new Date(this.dialogData.nextStudyPeriod.startsAt);
    const startTimeMin =
      previousStudyPeriod && this.isToday(previousStudyPeriod)
        ? this.convertToMinutes(previousStudyPeriod) + halfOfHourInMinutes
        : calendarHoursRange.start * minutesInHour;
    const startTimeMax =
      nextStudyPeriod && this.isToday(nextStudyPeriod)
        ? this.convertToMinutes(nextStudyPeriod)
        : calendarHoursRange.end * minutesInHour;
    const periodStart: number = data
      ? this.convertToMinutes(new Date(this.pureDate.setMinutes(data.startsAt)))
      : this.convertToMinutes(this.periodTimeRange.start);

    this.periodStartTimeOptions = data
      ? this.periodStartTimeOptions
      : this.getTimeArray(startTimeMin, startTimeMax - halfOfHourInMinutes);
    this.periodEndTimeOptions = this.getTimeArray(
      periodStart + halfOfHourInMinutes,
      startTimeMax
    );
    this.maxLessonDurationOptions = this.getTimeArray(
      longerLessonDuration || maxLessonDurationRange.min,
      maxLessonDurationMax
    );
    this.breakDurationOptions = this.getTimeArray(
      breakDurationRange.min,
      breakDurationMax
    );
    availableTimeRange.max =
      (+this.periodTimeRange.end - +this.periodTimeRange.start) / msInMinute;
    const availableTimeMin = this.dialogData.lessonsBelongCurrentStudyPeriod
      ?.filter(lesson => lesson.status !== 'canceled')
      .reduce((acc, lesson) => {
        return (
          acc +
          this.convertToMinutes(new Date(lesson.endsAt)) -
          this.convertToMinutes(new Date(lesson.startsAt))
        );
      }, 0);
    this.availableTimeOptions = this.getTimeArray(
      availableTimeMin || availableTimeRange.min,
      availableTimeMax
    );
  }

  private isToday(comparedDate?: Date): boolean {
    return comparedDate
      ? +new Date(comparedDate.toDateString()) === +this.pureDate
      : false;
  }

  private checkDialogFormChanges(): void {
    this.dialogForm.valueChanges.subscribe((data: IStudyPeriodForm) => {
      data.endsAt = this.getEndsAt(data);
      data.availableTime = this.getAvailableTime(data);
      data.maxLessonDuration = this.getMaxLessonDuration(data);
      data.breakDuration = this.getBreakDuration(data);
      this.getDialogFormOptions(data);

      this.dialogForm.patchValue(data, { emitEvent: false });
    });
  }
}
