import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss'],
  animations: [
    trigger('toggleTrigger', [
      state('off', style({ transform: 'translateX(0%)' })),
      state('on', style({ transform: 'translateX(120%)' })),
      transition('on <=> off', [animate('120ms ease-in-out')]),
    ]),
  ],
})
export class ThemeToggleComponent {
  @Input() public toggleOn = false;
  @Output() public onChangeTheme = new EventEmitter<boolean>();

  public toggleClick(): void {
    this.toggleOn = !this.toggleOn;
    this.onChangeTheme.emit(this.toggleOn);
  }
}
