<modal class="user" [class.dark-theme]="isDarkTheme$ | async">
  <modal-title class="d-none d-md-flex">
    {{ name.title }}
  </modal-title>
  <modal-body class="body">
    <div class="general">
      <div class="general__container">
        <div class="block block_row">
          <img
            class="info__img"
            [src]="user.avatarPaths.main | avatar"
            alt="User's avatar"
          />
          <app-description-item class="name" [description]="name">
            <span class="price d-md-none" *ngIf="user.role === 'student'" i18n
              >За урок - {{ getTutorFee(user) }} грн\час</span
            >
          </app-description-item>
        </div>
      </div>
    </div>
    <app-tabs
      class="d-none d-md-flex"
      [tabs]="tabs"
      [(activeTab)]="activeTab"
    ></app-tabs>
    <div class="main">
      <div class="block" [class.hidden]="activeTab === 'education'">
        <div class="block__title d-md-none" i18n>Личная информация</div>
        <app-description-item [description]="tel"></app-description-item>
        <app-description-item [description]="email"></app-description-item>
        <app-description-item [description]="telegram"></app-description-item>
        <app-description-item
          *ngIf="user.role === 'tutor'"
          [description]="meetingUrl"
          valueType="link"
        ></app-description-item>
      </div>
      <div class="block" [class.hidden]="activeTab === 'profile'">
        <div class="block__title d-md-none" i18n>Обучение</div>
        <app-description-item [description]="techStack"></app-description-item>
        <app-description-item [description]="skillLevel"></app-description-item>
        <app-description-item [description]="language"></app-description-item>
      </div>
      <div class="block">
        <div class="block__title d-md-none" i18n>Личная информация</div>
        <app-description-item [description]="gender"></app-description-item>
        <app-description-item
          class="locality"
          [description]="locality"
        ></app-description-item>
        <app-description-item
          [description]="country"
          valueType="iconed"
        ></app-description-item>
      </div>
    </div>
    <div class="separator">
      <hr class="d-md-none" />
    </div>
    <div class="footer__content" [class.hidden]="activeTab === 'education'">
      <app-description-item
        class="block footer__item"
        [description]="about"
      ></app-description-item>
      <app-description-item
        class="block footer__item"
        [description]="hobby"
      ></app-description-item>
    </div>
  </modal-body>
</modal>
