import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  HostBinding,
  HostListener,
} from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { SnackBarData } from '../../interfaces/snack-bar-data.interface';

@Component({
  selector: 'app-snack-bar',
  template: '<span data-test="userNotification">{{data.message}}</span>',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent implements OnInit, OnDestroy {
  @HostBinding('class') public readonly type = this.data.type;

  private timePassed = 0;
  private intervalRef = 0;

  private isHovered = false;

  public dragX = 0;
  public differenceX = 0;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData,
    private snackBarRef: MatSnackBarRef<unknown>
  ) {}

  public ngOnInit(): void {
    this.intervalRef = window.setInterval(() => {
      if (this.isHovered) {
        return;
      }

      this.timePassed += 100;

      if (this.timePassed >= 4000) {
        this.snackBarRef.dismiss();
      }
    }, 100);
  }

  public ngOnDestroy(): void {
    window.clearInterval(this.intervalRef);
  }

  @HostListener('mouseenter') public onMouseenter(): void {
    this.isHovered = true;
  }

  @HostListener('mouseleave') public onMouseleave(): void {
    this.isHovered = false;
  }

  @HostListener('touchstart', ['$event']) public onTouchStart(
    event: TouchEvent
  ): void {
    this.dragX = event.changedTouches[0].clientX;
  }

  @HostListener('touchend', ['$event']) public onTouchEnd(): void {
    const snackbar = document.querySelector('app-snack-bar') as HTMLElement;
    const halfWidth = snackbar.offsetWidth / 2;

    if (Math.abs(this.differenceX) > halfWidth) {
      this.snackBarRef.dismiss();
    } else {
      snackbar.style.opacity = '1';
      snackbar.style.transform = 'translateX(0)';
    }
  }

  @HostListener('touchmove', ['$event']) public onTouchMove(
    event: TouchEvent
  ): void {
    const positionX = event.changedTouches[0].clientX;

    const snackbar = document.querySelector('app-snack-bar') as HTMLElement;

    const halfWidth = snackbar.offsetWidth / 2;

    this.differenceX = positionX - this.dragX;
    snackbar.style.transform = `translateX(${`${this.differenceX}px`})`;

    snackbar.style.opacity = `${1 - Math.abs(this.differenceX) / halfWidth}`;
  }
}
