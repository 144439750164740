<modal class="popup" size="sm" [result]="null">
    <modal-title i18n> Фильтры </modal-title>
    <modal-body>
        <app-filter-panel
            class="filters"
            [filterDataGroup]="dialogData.panel.filterDataGroup"
            [searchControl]="dialogData.panel.searchControl ?? null"
            [defaultFilter]="dialogData.panel.defaultFilter"
            [roles]="dialogData.panel.roles"
            [teachers]="dialogData.panel.teachers"
            [students]="dialogData.panel.students"
            [statuses]="dialogData.panel.statuses"
            [operations]="dialogData.panel.operations"
            [dateRangePlaceholder]="dialogData.panel.dateRangePlaceholder"
        ></app-filter-panel>
    </modal-body>
    <modal-footer>
		<button class="stroked" [disabled]="isDefaultConfig" [matDialogClose]="dialogData.panel.defaultFilter" i18n>Отменить сортировку</button>
        <button class="primary" [disabled]="isDefaultConfig" [matDialogClose]="dialogData.panel.filterDataGroup.value" i18n>Применить</button>
    </modal-footer>
</modal>
