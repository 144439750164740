<button
  [class]="cancelButtonClass"
  [disabled]="isCancelButtonDisable"
  (click)="onCancelClick()"
>
  {{ cancelButtonTitle }}
</button>
<button
  class="primary"
  (click)="onConfirmClick()"
  [disabled]="isConfirmButtonDisable"
>
  {{ confirmButtonTitle }}
</button>
