import { environment } from 'src/environments/environment';
import { PayoutStatus } from 'src/interfaces/payout.interface';

export const serverUrl = environment.server;

export const userUrl = `api/users`;
export const userLocaleUrl = `api/users/me/locale`;
export const allPaymentsUrl = `api/payouts/payout`;

export const defaultAvatarsUrl = `api/users/me/avatars/default`;

export const loginUrl = `api/auth/login/`;
export const logoutUrl = `api/auth/logout`;
export const refreshToken = `api/auth/update-tokens`;
export const regUrl = `api/auth/signup/`;
export const sendEmailUrl = `api/auth/send-email-verification`;
export const forgotPasswordUrl = `api/auth/forgot-password/`;
export const changePasswordUrl = `api/auth/change-password`;

export const liqpayCheckoutDataUrl = 'api/payments/liqpay';
export const liqpayUrl = 'https://www.liqpay.ua/api/3/checkout';

export const resetPasswordUrl = (token: string): string =>
  `api/auth/reset-password/${token}`;
export const verificationEmailUrl = (token: string): string =>
  `api/auth/email-verification/${token}`;
export const paymentsUrl = (studentId: string, queryParams: string): string =>
  `api/payments/${studentId}${queryParams}`;
export const payoutsUrl = (tutorId: string): string =>
  `api/payouts/payout/${tutorId}`;
export const downloadPaymentDataUrl = `api/payments/download-pdf`;

export const lessonsUrl = (userId: string): string =>
  `api/users/${userId}/lessons`;

export const lessonsForPeriodUrl = (
  tutorId: string,
  startDate: string,
  endDate: string
): string => `api/users/${tutorId}/lessons?from=${startDate}&to=${endDate}`;

export const getUserByIdUrl = (id: string): string => `api/users/${id}`;

export const getStudyPeriodByTutorIdUrl = (
  tutorId: string,
  startDate?: string,
  endDate?: string
): string =>
  `api/users/${tutorId}/study-periods${
    startDate && endDate ? `?from=${startDate}&to=${endDate}` : ''
  }`;

export const getStudyPeriodByIdUrl = (studyPeriodId: string): string =>
  `api/study-periods/${studyPeriodId}`;

export const createLessonUrl = (studyPeriodId: string): string =>
  `api/study-periods/${studyPeriodId}/lessons`;
export const createLessonsReccurenceUrl = (studyPeriodId: string): string =>
  `api/study-periods/${studyPeriodId}/lessons/recurrence`;

export const updateLessonUrl = (lessonId: string): string =>
  `api/lessons/${lessonId}`;
export const updateLessonsReccurenceUrl = (lessonId: string): string =>
  `api/lessons/${lessonId}/recurrence`;

export const deleteStudyPeriodUrl = (studyPeriodId: string): string =>
  `api/study-periods/${studyPeriodId}`;
export const deleteStudyPeriodReccurenceUrl = (studyPeriodId: string): string =>
  `api/study-periods/${studyPeriodId}/recurrence`;

export const deleteLessonUrl = (lessonId: string): string =>
  `api/lessons/${lessonId}`;
export const deleteLessonReccurenceUrl = (lessonId: string): string =>
  `api/lessons/${lessonId}/recurrence`;

export const cancelLessonUrl = (lessonId: string): string =>
  `api/lessons/${lessonId}/status/canceled`;
export const cancelLessonReccurenceUrl = (lessonId: string): string =>
  `api/lessons/${lessonId}/recurrence/status/canceled`;

export const approveLessonUrl = (lessonId: string): string =>
  `api/lessons/${lessonId}/status/approved`;
export const approveLessonReccurenceUrl = (lessonId: string): string =>
  `api/lessons/${lessonId}/recurrence/status/approved`;

export const createStudyPeriodUrl = (userId: string): string =>
  `api/users/${userId}/study-periods/`;
export const createStudyPeriodsReccurenceUrl = (userId: string): string =>
  `api/users/${userId}/study-periods/recurrence`;

export const changeStudyPeriodUrl = (studyPeriodId: string): string =>
  `api/study-periods/${studyPeriodId}`;
export const changeStudyPeriodsReccurenceUrl = (
  studyPeriodId: string
): string => `api/study-periods/${studyPeriodId}/recurrence`;

export const createBlockedStudyPeriod = (studyPeriodId: string): string =>
  `api/study-periods/${studyPeriodId}/lessons/period-lock`;
export const updateOrDeleteBlockedStudyPeriod = (
  blockedPeriodId: string
): string => `api/lessons/${blockedPeriodId}/period-lock`;

export const getMyStudents = 'api/users/me/students';

export const ALL_PAYOUTS_URL = 'api/payouts/payout/';
export const UPDATE_PAYOUT = (id: string, status: PayoutStatus): string =>
  `${ALL_PAYOUTS_URL}${id}/status/${status}`;
