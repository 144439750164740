import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpContextToken,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, finalize, share } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { refreshToken } from 'src/app/endpoints/endpoints';

export const IGNORE_REFRESH_TOKEN = new HttpContextToken<boolean>(() => false);

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private tokenRefresh$?: Observable<unknown>;

  constructor(private auth: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (
          !(err instanceof HttpErrorResponse) ||
          request.context.get(IGNORE_REFRESH_TOKEN)
        ) {
          return throwError(err);
        }
        const isUnauthorized = err.status === 401;
        const isNotFound = err.status === 404;
        const isRefreshTokeRequest = err.url?.includes(refreshToken);

        //Коли закінчилась дія ajwt токена і не було запита на rjwt
        if (isUnauthorized && !isRefreshTokeRequest) {
          return this.refreshToken().pipe(
            switchMap(() => {
              return next.handle(request);
            })
          );
        }

        //Коли закінчилась дія rjwt токена
        if (isUnauthorized && isRefreshTokeRequest && this.auth.user) {
          window.location.href = '';
        }

        //Ця помилка виникає коли не вдалось знайти користувача якому належить rjwt
        if (isNotFound && isRefreshTokeRequest && this.auth.user) {
          window.location.href = '';
        }

        return throwError(err);
      })
    );
  }

  refreshToken(): Observable<unknown> {
    if (this.tokenRefresh$) {
      return this.tokenRefresh$;
    }

    this.tokenRefresh$ = this.auth.refreshToken().pipe(
      finalize(() => {
        delete this.tokenRefresh$;
      }),
      share()
    );

    return this.tokenRefresh$;
  }
}
