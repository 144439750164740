<div class="wrapper">
  <div class="item">
    <div class="color booked"></div>
    <span i18n>Забронированный урок</span>
  </div>
  <div class="item">
    <div class="color approved"></div>
    <span i18n>Подтвержденный урок</span>
  </div>
  <div class="item">
    <div class="color completed"></div>
    <span i18n>Завершенный урок</span>
  </div>
  <div class="item">
    <div class="color negative"></div>
    <span i18n>Негативный баланс</span>
  </div>
  <div *ngIf="isShow" class="item">
    <div class="color unique"></div>
    <span i18n>Уроки других студентов</span>
  </div>
  <div class="item">
    <div class="color blocked">
      <img class="blocked-img" alt="blocked icon" src="assets/icons/lock.svg" />
    </div>
    <span i18n>Заблокированный период</span>
  </div>
</div>
