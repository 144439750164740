import { Injectable } from '@angular/core';
import { languagesObj } from 'src/app/constants/language.constants';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	public readonly defaultLanguage = this.authSerivce.user?.locale || 'uk';

	public language = this.getCurrentLanguage();

	constructor(private authSerivce: AuthService){}

	private getCurrentLanguage(): string {
		const paramsArr = window.location.pathname.split('/');

		const language = paramsArr[1];
		const allowedLagnuages = Object.keys(languagesObj);

		if (allowedLagnuages.includes(language)) {
			return language;
		}

		return this.defaultLanguage;
	}
}
