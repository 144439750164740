import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { StatusColor, StatusData } from '../../interfaces/status.interface';

@Component({
  selector: 'status-icon',
	template: '<i class="fa-solid fa-check"></i>',
	host: { class: 'e-status-icon' },
})
export class StatusIconComponent<T extends string | number> implements OnChanges {
  @Input() public data!: StatusData<T>;
	@Input() public status!: T;

	@HostBinding('class') public color!: StatusColor;

	public ngOnChanges(): void {
		const item = this.data[this.status];

		this.color = item.color;
	}

}
