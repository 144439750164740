import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ISelectData, ISortConfig } from 'src/interfaces/filters.interface';
import { DestroyService } from '../../services/destroy.service';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';
import { Sort } from '../../modules/table/directives/table-sort/table-sort.directive';

interface ISortWindowConfig {
  fields: ISelectData[];
  defaultConfig: ISortConfig;
  currentConfig: ISortConfig;
}

type SortFromGroup = FormGroup<{
  key: FormControl<string | null>;
  order: FormControl<Sort | null>;
}>;

@Component({
  selector: 'app-mobile-sort-window',
  templateUrl: './mobile-sort-window.component.html',
  styleUrls: ['./mobile-sort-window.component.scss'],
  providers: [DestroyService],
})
export class MobileSortWindowComponent implements OnInit {
  public sortFormGroup: SortFromGroup = new FormGroup({
    key: new FormControl<string | null>(null),
    order: new FormControl<Sort | null>(null),
  });
  public fields!: ISelectData[];
  public order: ISelectData[] = [
    { value: 'asc', text: $localize`По возрастанию` },
    { value: 'desc', text: $localize`По убыванию` },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ISortWindowConfig,
    private readonly destroy$: DestroyService
  ) {}

  public ngOnInit(): void {
    this.fields = this.dialogData.fields;
    this.sortFormGroup.patchValue(this.dialogData.currentConfig);

    this.sortFormGroup.valueChanges
      .pipe(
        startWith(this.sortFormGroup.getRawValue()),
        pairwise(),
        takeUntil(this.destroy$)
      )
      .subscribe(([prev, next]) => {
        const keySetWithoutOrder =
          !prev.key && next.key && next.order === 'no-sort';

        if (keySetWithoutOrder) {
          this.sortFormGroup.controls.order.setValue('asc');
        }
      });
  }

  public get isDefaultConfig(): boolean {
    const equalKeys =
      this.dialogData.defaultConfig.key ===
      this.sortFormGroup.get('key')?.value;
    const equalOrders =
      this.dialogData.defaultConfig.order ===
      this.sortFormGroup.get('order')?.value;
    return equalKeys || equalOrders;
  }
}
