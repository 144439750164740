import { Directive } from '@angular/core';

@Directive({
	selector: 'card-header',
	host: { class: 'card-header' },
})
export class CardHeaderDirective {}

@Directive({
	selector: 'card-body',
	host: { class: 'card-body' },
})
export class CardBodyDirective {}

@Directive({
	selector: 'card-footer',
	host: { class: 'card-footer' },
})
export class CardFooterDirective {}

@Directive({
	selector: 'card-aside',
	host: { class: 'card-aside' },
})
export class CardAsideDirective {}

@Directive({
	selector: 'card-info-title',
	host: { class: 'card-info-title' },
})
export class CardInfoTitleDirective {}

@Directive({
	selector: 'card-info-description',
	host: { class: 'card-info-description' },
})
export class CardInfoDescriptionDirective {}
@Directive({
	selector: 'card-info-img',
	host: { class: 'card-info-img' },
})
export class CardInfoImgDirective {}
