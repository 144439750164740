export enum SkillLevel {
  Beginner = 'beginner',
  Basic = 'basic',
  Advanced = 'advanced',
}

export enum Teaching {
  HTML = 'html',
  CSS = 'css',
  JavaScript = 'js',
  React = 'react',
  Vue = 'vue',
  Angular = 'angular',
  Python = 'python',
  Java = 'java',
  Nodejs = 'nodejs',
  QA = 'qa',
  English = 'english',
  Informatics = 'informatics',
  WebDesign = 'web design',
}

export enum Language {
  Russian = 'ru',
  Ukrainian = 'ua',
  English = 'eng',
}

export enum SourceOfDiscovery {
  instagramFacebook = 'Instagram/Facebook',
  google = 'Google',
  buki = 'Buki',
  olx = 'Olx',
  recommendations = 'Recommendations',
}
