<div class="notification-content">
  <form>
    <form class="notification-item" [formGroup]="class1">
      <p class="title" i18n>Информационные</p>
      <div class="item">
        <div class="checkbox">
          <mat-checkbox
            color="primary"
            [formControlName]="NotificationClassesDestination.email"
            labelPosition="before"
          >
            <mat-label>E-mail</mat-label>
          </mat-checkbox>
        </div>
      </div>
      <div class="item">
        <div class="checkbox">
          <mat-checkbox
            color="primary"
            [formControlName]="NotificationClassesDestination.telegram"
            labelPosition="before"
          >
            <mat-label>Telegram</mat-label>
          </mat-checkbox>
        </div>
      </div>
    </form>
    <form class="notification-item" [formGroup]="class2">
      <p class="title" i18n>Важные</p>
      <div class="item">
        <div class="checkbox">
          <mat-checkbox
            color="primary"
            [formControlName]="NotificationClassesDestination.email"
            [disabled]="isEmailDisabled2"
            labelPosition="before"
          >
            <mat-label>E-mail</mat-label>
          </mat-checkbox>
        </div>
      </div>
      <div class="item">
        <div class="checkbox">
          <mat-checkbox
            color="primary"
            [formControlName]="NotificationClassesDestination.telegram"
            [disabled]="isTelegramDisabled2"
            labelPosition="before"
          >
            <mat-label>Telegram</mat-label>
          </mat-checkbox>
        </div>
      </div>
    </form>
    <form class="notification-item" [formGroup]="class3">
      <p class="title" i18n>Высокого приоритета</p>
      <div class="item">
        <div class="checkbox">
          <mat-checkbox
            color="primary"
            [formControlName]="NotificationClassesDestination.email"
            [disabled]="isEmailDisabled3"
            labelPosition="before"
          >
            <mat-label>E-mail</mat-label>
          </mat-checkbox>
        </div>
      </div>
      <div class="item">
        <div class="checkbox">
          <mat-checkbox
            color="primary"
            [formControlName]="NotificationClassesDestination.telegram"
            [disabled]="isTelegramDisabled3"
            labelPosition="before"
          >
            <mat-label>Telegram</mat-label>
          </mat-checkbox>
        </div>
      </div>
    </form>
  </form>
</div>
