import { UserRole } from "src/interfaces/user.interface";

export const roleI18n: Record<UserRole, string> = {
	[UserRole.Admin]: $localize `Администратор`,
    [UserRole.Tutor]:  $localize `Преподаватель`,
    [UserRole.Student]:  $localize `Студент`,
};


export const rolesI18n: Record<UserRole, string> = {
	[UserRole.Admin]:  $localize `Администраторы`,
    [UserRole.Tutor]:  $localize `Преподаватели`,
    [UserRole.Student]:  $localize `Студенты`,
};
