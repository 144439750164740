import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { defaultLessonCostPerHour } from 'src/app/constants/user.constants';
import { ILessonPriceData } from 'src/interfaces/user.interface';

@Pipe({
  name: 'lessonPrices',
})
export class LessonPricePipe implements PipeTransform {
  transform(lessonPrices: ILessonPriceData[]): string | null {
    const decimalPipe = new DecimalPipe('en');

    if (!lessonPrices.length) {
      return decimalPipe.transform(defaultLessonCostPerHour, '1.2', 'en');
    }

    const lessonCosts = lessonPrices.map(lessonPriceData =>
      decimalPipe.transform(lessonPriceData.lessonCostPerHour, '1.2', 'en')
    );
    return lessonCosts.join(' / ');
  }
}
