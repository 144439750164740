import { NgModule } from '@angular/core';
import { StatusComponent } from './components/status/status.component';
import { StatusDirective } from './directives/status.directive';
import { StatusIconComponent } from './components/status-icon/status-icon.component';

@NgModule({
	declarations: [StatusComponent, StatusDirective, StatusIconComponent,],
	exports: [StatusComponent, StatusDirective, StatusIconComponent],
})
export class StatusModule {}
