import {
  Component,
  OnChanges,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { PayoutStrategy } from '../../strategies/payout.strategy';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'payout-form',
  template: '<ng-container #paymentForm></ng-container>',
  styleUrls: ['./payout-form.component.scss'],
})
export class PayoutFormComponent implements OnChanges {
  @Input() public strategy!: PayoutStrategy;
  @Input() public formGroup!: UntypedFormGroup;
  @Input() public isReadonly = false;
  @Input() public isCopy = false;

  @ViewChild('paymentForm', { static: true, read: ViewContainerRef })
  public paymentFormRef!: ViewContainerRef;

  public ngOnChanges(): void {
    this.paymentFormRef.clear();

    const componentRef = this.paymentFormRef.createComponent(
      this.strategy.formComponent
    );

    componentRef.instance.formGroup = this.formGroup;
    componentRef.instance.isReadonly = this.isReadonly;
    componentRef.instance.isCopy = this.isCopy;
    componentRef.changeDetectorRef.detectChanges();
  }
}
