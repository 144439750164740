import { Component, Input, HostBinding, ContentChild } from '@angular/core';
import { ModalFooterDirective } from '../../directives/modal.directive';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent{
  @HostBinding('class') 
  @Input() public size: 'sm' | 'md' | 'lg' = 'md'
  @Input() public withCloseButton = true;

  @HostBinding('class.with-footer') 
  @ContentChild(ModalFooterDirective) public modalFooter!: ModalFooterDirective

  @Input() public result?: unknown;
}
