<div class="message__content">
  <img
    class="message__img"
    [src]="message.senderUrl || defaultNotificationIconPath"
  />
  <span class="message__text">{{ message.message }}</span>
</div>
<div class="message__footer">
  <span class="message__date d-md-none">{{
    message.updatedAt || message.createdAt | date: 'dd.LL.yyyy HH:mm'
  }}</span>
  <div class="message__button wrapper">
    <span class="message__button" (click)="onMessageClick(message)" i18n
      >Открыть</span
    >
    <span
      class="message__button"
      *ngIf="canDeleteMessage(message)"
      (click)="deleteMessage(message, $event)"
      i18n
    >
      Скрыть
    </span>
  </div>
</div>
