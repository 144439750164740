import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class AccessGuard  {
	constructor(private auth: AuthService, private router: Router) {}

	public canActivate(): Observable<boolean> {
		if (!this.auth.isAuthenticated()) {
			this.router.navigate([''], { queryParams: { accessDenied: true } });
			return of(false);
		}

		if (this.auth.hasAccess()) {
			this.auth.user?.role === 'admin'
				? this.router.navigate([`/dashboard/admin`])
				: this.router.navigate([`/dashboard/calendar`]);

			return of(false);
		}

    return of(true)
	}
}
