import { Component, Inject, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { placeholders } from 'src/app/constants/placeholders.constants';
import { minutesInHour, msInHalfHour, msInHour, msInMinute } from 'src/app/constants/time.constants';
import { ICalendarItem, IRange } from 'src/interfaces/calendar.interface';
import { ISelectData } from 'src/interfaces/filters.interface';
import { IStudyPeriod } from 'src/interfaces/study-period.interface';
import { ITutorUser } from 'src/interfaces/user.interface';
import { ActionsStudyPeriodsComponent } from '../actions-study-periods/actions-study-periods.component';

@Component({
  selector: 'app-block-period-actions',
  templateUrl: './block-period-actions.component.html',
  styleUrls: ['./block-period-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockPeriodActionsComponent implements OnInit {
  public dialogForm: UntypedFormGroup = new UntypedFormGroup({});
  public startTime: ISelectData[] = [];
  public endTime: ISelectData[] = [];
  public placeholders = placeholders;

  constructor(
    private dialogRef: MatDialogRef<ActionsStudyPeriodsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ICalendarItem,
  ) { }

  public get endControlHasError(): boolean {
    const endControl = <UntypedFormControl>this.dialogForm.get('endsAt');
    return endControl.hasError('required') || endControl.invalid;
  }

  public get date(): IRange<Date> {
    if (this.dialogData.blockedTime) {
      return {
        start: new Date(this.dialogData.blockedTime.startsAt),
        end:  new Date(this.dialogData.blockedTime.endsAt)
      };
    }
    return {
      start: this.dialogData.date,
      end: +new Date(+this.dialogData.date + msInHour) > +new Date((<IStudyPeriod>this.dialogData.studyPeriod).endsAt)
        ? new Date(+this.dialogData.date + msInHalfHour)
        : new Date(+this.dialogData.date + msInHour)
    };
  }

  public get title(): string {
    return this.dialogData.blockedTime
      ? $localize `Редактировать блокирование`
      : $localize `Блокирование учебного периода`;
  }

  public ngOnInit(): void {
    this.startTime = this.getAvailableStartTime();
    this.endTime = this.getAvailableEndTime();
    const defaultStart = this.startTime.find(item => +item.value === + this.date.start)
    const defaultEnd = this.endTime.find(item => +item.value === + this.date.end)
    this.dialogForm.addControl('startsAt', new UntypedFormControl(defaultStart?.value, Validators.required));
    this.dialogForm.addControl('endsAt', new UntypedFormControl(defaultEnd?.value, Validators.required));
    this.dialogForm.addControl('studyPeriodId', new UntypedFormControl(this.dialogData.studyPeriod?._id, Validators.required));
    if (this.dialogData.blockedTime) {
      this.dialogForm.addControl('blockedStudyPeriodId', new UntypedFormControl(this.dialogData.blockedTime._id, Validators.required));
    }
  }

  private getAvailableStartTime(): ISelectData[] {
    const studyPeriod = this.dialogData.studyPeriod as IStudyPeriod;
    const lessonBefore = this.dialogData.lessonsBelongCurrentStudyPeriod?.reverse().find(lesson => +new Date(lesson.endsAt) <= +this.date.start);
    const lessonAfter = this.dialogData.lessonsBelongCurrentStudyPeriod?.find(lesson => +new Date(lesson.startsAt) >= +this.date.end);
    const breackDurationInMs = (<ITutorUser>this.dialogData.tutor).studyPeriodDefaultBreakDuration * msInMinute;
    const periodStart = lessonBefore ? new Date(+new Date(lessonBefore.endsAt) + breackDurationInMs) : new Date(studyPeriod.startsAt);
    const periodEnd = lessonAfter? new Date(+new Date(lessonAfter.startsAt) - (msInHalfHour + breackDurationInMs)) : new Date(+new Date(studyPeriod.endsAt) - msInHalfHour);

    return this.getTimeArray(periodStart, periodEnd);
  }

  private getAvailableEndTime(): ISelectData[] {
    const studyPeriod = this.dialogData.studyPeriod as IStudyPeriod;
    const lessonAfter = this.dialogData.lessonsBelongCurrentStudyPeriod?.find(lesson => +new Date(lesson.startsAt) >= +this.date.end);
    const breackDurationInMs = (<ITutorUser>this.dialogData.tutor).studyPeriodDefaultBreakDuration * msInMinute;
    const periodStart =  new Date(+this.date.start + msInHalfHour)
    const periodEnd = lessonAfter ? new Date(+new Date(lessonAfter.startsAt) - breackDurationInMs) : new Date(studyPeriod.endsAt);
    return this.getTimeArray(periodStart, periodEnd);
  }

  public onButtonClick(confirm: boolean): void {
    const data = confirm ? this.dialogForm.value : false;
    this.dialogRef.close(data)
  }

  private getTimeArray(min: Date, max: Date, step = msInHalfHour): ISelectData[] {
    const durationOptions: ISelectData[] = []

    while(+min <= +max) {
      const time = min.getHours() * minutesInHour + min.getMinutes();
      const timeString = this.toHoursAndMinutes(time);
      durationOptions.push({value: min, text: timeString});
      min = new Date(+min + step);
    }

    return durationOptions
  }

  private toHoursAndMinutes(totalMinutes: number): string {
    const hours = Math.floor(totalMinutes / minutesInHour);
    const minutes = totalMinutes % minutesInHour;
    return `${this.padToTwoDigits(hours)}:${this.padToTwoDigits(minutes)}`;
  }

  private padToTwoDigits(num: number): string {
    return num.toString().padStart(2, '0');
  }
}
