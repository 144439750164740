export const placeholders = {
  workTime: $localize`Рабочее время`,
  periodStart: $localize`Начало периода`,
  periodEnd: $localize`Конец периода`,
  selectTime: $localize`Выберите время`,
  selectField: $localize`Выберите поле`,
  maxLessonDuration: $localize`Максимальная продолжительность урока`,
  breakDuration: $localize`Продолжительность перерыва`,
  availableTime: $localize`Доступное время`,
  selectStudent: $localize`Выбрать студента`,
  selectTutor: $localize`Выбрать преподавателя`,
  selectRole: $localize`Выбрать роль`,
  selectStatus: $localize`Выбрать статус`,
  selectOperation: $localize`Выбрать операцию`,
  start: $localize`Начало`,
  end: $localize`Конец`,
  blockedStart: $localize`Начало блокирования`,
  blockedEnd: $localize`Окончание блокирования`,
  operationDate: $localize`Дата операции`,
  dateRangePlaceholder: $localize`Дата регистрации`,
};
