import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard  {
	constructor(private auth: AuthService, private router: Router) {}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		if (this.auth.isAuthenticated() && this.auth.hasAccess()) {
			this.auth.user?.role === 'admin'
				? this.router.navigate([`/dashboard/admin`])
				: this.router.navigate([`/dashboard/calendar`]);
			return of(false);
		}

		if (this.auth.isAuthenticated() && !this.auth.hasAccess()) {
			return this.auth.logoutApi().pipe(
				map(() => true),
				catchError(() => of(true)) 
			);
		}

		return of(true);
	}
}
