<modal size="sm">
  <modal-title>{{ title }}</modal-title>
  <modal-body>
    <app-actions-info
      [date]="periodTimeRange"
      [hasTime]="isEdit"
      [timeTitle]="placeholders.workTime"
      [isStatusVisible]="false"
    ></app-actions-info>
    <div class="period-user-description">{{ userName }}</div>
    <form class="form" [formGroup]="dialogForm">
      <div class="inputs-wrapper">
        <app-label-container
          class="selects__item"
          [labelText]="placeholders.periodStart"
        >
          <app-select
            [formGroup]="dialogForm"
            controlName="startsAt"
            [placeholderText]="placeholders.selectTime"
            [withResetButton]="false"
            [optionsList]="periodStartTimeOptions"
          ></app-select>
        </app-label-container>
        <app-label-container
          class="selects__item"
          [labelText]="placeholders.periodEnd"
        >
          <app-select
            [formGroup]="dialogForm"
            controlName="endsAt"
            [placeholderText]="placeholders.selectTime"
            [withResetButton]="false"
            [optionsList]="periodEndTimeOptions"
          ></app-select>
        </app-label-container>
      </div>
      <app-label-container
        class="selects__item"
        [labelText]="placeholders.maxLessonDuration"
      >
        <app-select
          [formGroup]="dialogForm"
          controlName="maxLessonDuration"
          [placeholderText]="placeholders.selectTime"
          [withResetButton]="false"
          [optionsList]="maxLessonDurationOptions"
          [disabled]="endControlHasError"
        ></app-select>
      </app-label-container>
      <app-label-container
        class="selects__item"
        [labelText]="placeholders.breakDuration"
      >
        <app-select
          [formGroup]="dialogForm"
          controlName="breakDuration"
          [placeholderText]="placeholders.selectTime"
          [withResetButton]="false"
          [optionsList]="breakDurationOptions"
          [disabled]="endControlHasError"
        ></app-select>
      </app-label-container>
      <div class="inputs-wrapper">
        <app-label-container
          class="selects__item"
          [labelText]="placeholders.availableTime"
        >
          <app-select
            [formGroup]="dialogForm"
            controlName="availableTime"
            [placeholderText]="placeholders.selectTime"
            [withResetButton]="false"
            [optionsList]="availableTimeOptions"
            [disabled]="endControlHasError"
          ></app-select>
        </app-label-container>
        <div class="checkbox">
          <mat-checkbox
            color="primary"
            formControlName="isRepeat"
            [disabled]="isRepeatCheckboxDisabled"
          >
            <mat-label
              class="checkbox-label"
              [class.checkbox-label_disabled]="isRepeatCheckboxDisabled"
              i18n
              >Повторять еженедельно</mat-label
            >
          </mat-checkbox>
        </div>
      </div>
    </form>
  </modal-body>
  <modal-footer>
    <app-action-buttons
      (buttonClick)="onButtonClick($event)"
      [isConfirmButtonDisable]="
        endControlHasError || (isEdit && !(isDirty$ | async))
      "
    ></app-action-buttons>
  </modal-footer>
</modal>
