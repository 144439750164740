import { Country } from './country.interface';
import { INotificationClassesDestinaion } from './notification.interface';
import { Language, SkillLevel, Teaching } from './study.interface';
import { CardPayData, PayPalPayData } from './payout.interface';
import { IResponseBase } from './response.interface';

export enum UserRole {
  Admin = 'admin',
  Student = 'student',
  Tutor = 'tutor',
}

export enum Gender {
  Unspecified = 'unspecified',
  Male = 'male',
  Female = 'female',
}

export enum Locale {
  uk = 'uk',
  ru = 'ru',
  en = 'en',
}

export type BalanceStatus = 'success' | 'error' | 'not checked';

export interface IUserBase {
  firstName: string;
  lastName: string;
  fullName: string;
  role: UserRole;
  avatarPaths: AvatarPaths;
  id: string;
  email: string;
  contacts: Contacts; // TODO remove after refactoring
  gender: Gender;
  country: Country;
  locality: string; //city
  phoneCountry: Country;
  phone: string;
  telegram: string;
  balanceStatus: BalanceStatus;
}

export interface IUserCommon extends IUserBase {
  active: boolean;
  verifiedEmail: boolean;
  verifiedRole: null | boolean;
  timeZone: string;
  language: Language[];
  createdAt: string;
  notificationClassesDestination: INotificationClassesDestinaion;
  about: string;
  hobby: string;
  locale: Locale;
  commentFromAdmin: string;
  theme: Theme;
}

export type Theme = 'dark' | 'light';

export interface IUserSimple {
  skillLevel: SkillLevel;
  techStack: Teaching[];
  balance: number;
}

export interface IAdminUser extends IUserCommon {
  role: UserRole.Admin;
}

export interface ILessonPriceData {
  tutorId: string;
  lessonCostPerHour: number;
  tutorFeePerHour: number;
}

export interface ITutorUser extends IUserSimple, IUserCommon {
  _id: string;
  role: UserRole.Tutor;
  bookingAndCancelationTimeConstraints: number;
  meetingUrl: string;
  minTimeFromLessonBookingToItsStart: number;
  minTimeFromLessonCancellationToItsStart: number;
  studyPeriodDefaultAvailableTime: number;
  studyPeriodDefaultBreakDuration: number;
  studyPeriodDefaultMaxLessonDuration: number;
  maxNumberOfStudents: number;
  payMethod: 'payPal' | 'card';
  payData: CardPayData | PayPalPayData;
  sourceOfDiscovery: string;
  students: IStudentUser[];
}

export interface IStudentUser extends IUserSimple, IUserCommon {
  role: UserRole.Student;
  tutors: ITutorUser[];
  sourceOfDiscovery: string;
  payMethod?: 'payPal' | 'card';
  payData?: CardPayData | PayPalPayData;
  lessonPrices: ILessonPriceData[];
}

export interface IResponseUsers extends IResponseBase {
  numberOfUsers: number;
  users: IUser[];
  summary: {
    allUsers: number;
    selectedUsers: number;
  };
  filterData: IUsersFilterData;
}

export interface IUsersFilterData {
  roles: UserRole[];
  tutors: { id: string; fullName: string }[];
}

export interface IResponseUser {
  status: string;
  user: IUser;
}

export type IUser = IAdminUser | ITutorUser | IStudentUser;

export type AvatarPaths = {
  main: string;
  mini: string;
};

export type Contacts = {
  phone: string | null;
  telegram: string | null;
};
