import { Component, Input, OnInit } from '@angular/core';
import { roleI18n } from 'src/app/i18n/role.i18n';
import { CountryOption } from 'src/interfaces/option.interface';
import { IUserBase } from 'src/interfaces/user.interface';
import countries from '../../constants/country.json';
import { genderi18n } from 'src/app/i18n/gender.i18n';

@Component({
  selector: 'user-info-card',
  templateUrl: './user-info-card.component.html',
  styleUrls: ['./user-info-card.component.scss'],
})
export class UserInfoCardComponent implements OnInit {
  @Input() public user!: IUserBase;
  @Input() public price!: number;

  public readonly roleI18n = roleI18n;
  public readonly genderI18n = genderi18n;

  public selectedOptionObj!: CountryOption;

  public ngOnInit(): void {
    this.selectedOptionObj = countries.find(
      country => country.code === this.user.country
    ) as CountryOption;
  }
}
