import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './components/card/card.component';
import {
	CardBodyDirective,
	CardFooterDirective,
	CardHeaderDirective,
	CardAsideDirective,
	CardInfoTitleDirective,
	CardInfoDescriptionDirective,
	CardInfoImgDirective,
} from './directives/card.directive';
import { CardInfoComponent } from './components/card-info/card-info.component';

@NgModule({
	declarations: [
		CardComponent,
		CardHeaderDirective,
		CardBodyDirective,
		CardFooterDirective,
		CardAsideDirective,
		CardInfoTitleDirective,
		CardInfoDescriptionDirective,
		CardInfoImgDirective,
		CardInfoComponent,
	],
	imports: [CommonModule],
	exports: [
		CardComponent,
		CardHeaderDirective,
		CardBodyDirective,
		CardFooterDirective,
		CardAsideDirective,
		CardInfoTitleDirective,
		CardInfoDescriptionDirective,
		CardInfoImgDirective,
		CardInfoComponent,
	],
})
export class CardModule {}
