import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { serverUrl } from 'src/app/endpoints/endpoints';
import { LanguageService } from 'src/app/shared/services/language.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private languageService: LanguageService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(
      request.clone({
        withCredentials: true,
        url: `${serverUrl}${request.url}`,
        setHeaders: {
          locale: this.languageService.language
        }
      })
    );
  }
}
