import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryOption } from 'src/interfaces/option.interface';
import countries from '../../constants/country.json'

@Component({
  selector: 'country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CountrySelectComponent,
      multi: true,
    },
  ],
})
export class CountrySelectComponent implements ControlValueAccessor {
  @Input() isFlagOnly = true;
  @Input() public isRequired = false;
  
  public options = countries;
  public selectedOptionObj!: CountryOption;
  public value = '';

  private onChange = (value: string): void => {};
  private onTouch = () => {};

  constructor(private changeDetector: ChangeDetectorRef) {}

  public writeValue(value: string): void {
    this.value = value;
    this.selectedOptionObj = this.options.find((country) => country.code === this.value) as CountryOption;
    this.changeDetector.detectChanges();
  }
  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public onCountrySelect(event: string): void{
    this.value = event;
    this.selectedOptionObj = this.options.find((x) => x.code === this.value) as CountryOption;
    this.onTouch()
    this.onChange(event);
    this.changeDetector.detectChanges();
  }
}
