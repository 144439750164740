import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { IContextMenuItem } from 'src/interfaces/calendar.interface';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'contextMenu'
})
export class ContextMenuComponent {
  @ViewChild(MatMenu, {static: true}) public menu!: MatMenu;
  @Output() public menuItemAction: EventEmitter<IContextMenuItem> = new EventEmitter<IContextMenuItem>();

  public menuItemClick(event: IContextMenuItem): void {
    this.menuItemAction.emit(event)
  }
}
