import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
  selector: 'modal-title',
  host: { class: 'modal-title' },
})
export class ModalTitleDirective {}


@Directive({
  selector: 'modal-body',
  host: { class: 'modal-body' },
})
export class ModalBodyDirective {}

@Directive({
  selector: 'modal-footer',
  host: { class: 'modal-footer' },
})
export class ModalFooterDirective {}

@Directive({
  selector: 'modal-aside',
  host: { class: 'modal-aside' },
})
export class ModalAsideDirective {
  @HostBinding('class.active') 
  @Input() public isOpen = false;
}
