import { ComponentType } from '@angular/cdk/portal';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import {
  MatCalendar,
  MatCalendarHeader,
  MatDatepicker,
} from '@angular/material/datepicker';
import { placeholders } from 'src/app/constants/placeholders.constants';
import { DatePeriod } from 'src/app/dashboard/admin-payout/utils/date-period';
import { NullableDateRange } from 'src/interfaces/date-range.interface';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'custom-header',
  template: `
    <div class="custom-header">
      <span class="custom-header-content"> {{ periodLabel }}</span>
    </div>
  `,
  styles: [
    `
      .custom-header {
        height: 3em;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #dce7fc;
        margin-bottom: 5%;
      }
    `,
  ],
})
export class CustomHeader<D> {
  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats
  ) {}

  get periodLabel(): string {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        this._dateFormats.display.monthYearLabel
      )
      .toLocaleUpperCase();
  }
}
@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateRangePickerComponent,
      multi: true,
    },
  ],
})
export class DateRangePickerComponent implements ControlValueAccessor, OnInit {
  @Input() public placeholderText = 'Выберите дату';
  @Input() public currentDate: Date | DatePeriod = new Date();
  @Input() public disableMonthSelection = false;
  @ViewChild('picker') datePicker!: MatDatepicker<any>;
  @ViewChild(MatCalendarHeader) defaultHeader!: typeof MatCalendarHeader;
  public start: Nullable<Date> = null;
  public end: Nullable<Date> = null;
  public placeholders = placeholders;

  public customHeader!: ComponentType<any>;

  private onChange!: (value: Nullable<NullableDateRange>) => void;
  private onTouch = (): void => {};

  constructor(
    private languageService: LanguageService,
    private _dateAdapter: DateAdapter<Date>
  ) {
    this._dateAdapter.setLocale(this.languageService.language);
  }

  ngOnInit(): void {
    this.customHeader = this.disableMonthSelection
      ? CustomHeader
      : this.defaultHeader;
  }

  writeValue(value: Nullable<NullableDateRange>): void {
    this.start = value?.start;
    this.end = value?.end;
  }
  registerOnChange(fn: (value: Nullable<NullableDateRange>) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public get startAt(): Date {
    return this.currentDate instanceof Date
      ? this.currentDate
      : this.currentDate?.start || new Date(); // TODO: remove this one after implement date filter
  }

  public onClosed(): void {
    const end = this.end ? this.getEndOfDay(this.end) : null;

    this.onTouch();
    this.onChange({ start: this.start, end });
  }

  public clearStartDate(event: MouseEvent): void {
    event.stopPropagation();
    this.start = null;
    this.end = null;
    this.onChange({ start: this.start, end: this.end });
  }

  private getEndOfDay(date: Date): Date {
    const day = new Date(date);

    day.setHours(23);
    day.setMinutes(59);
    day.setSeconds(59);

    return day;
  }
}
