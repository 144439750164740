import { Component, Input } from '@angular/core';
import { IRange } from 'src/interfaces/calendar.interface';
import { ThemeService } from '../../services/theme.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-actions-info',
  templateUrl: './actions-info.component.html',
  styleUrls: ['./actions-info.component.scss'],
})
export class ActionsInfoComponent {
  @Input() date!: IRange<Date>;
  @Input() hasDateRange = false;
  @Input() hasTime = true;
  @Input() hideTime = false;
  @Input() isStatusVisible = true;
  @Input() hasStatus = true;
  @Input() status = '-:- — -:-';
  @Input() statusClass = '';
  @Input() timeTitle = $localize`Время`;

  public isDarkTheme$: Observable<boolean> = this.themeService.isDarkTheme$;

  constructor(private themeService: ThemeService) {}
}
