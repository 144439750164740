import { Component, EventEmitter, Input, Output } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { roleI18n } from 'src/app/i18n/role.i18n';
import { INavItem, navItems } from 'src/app/constants/sidebar.constants';
import { ThemeService } from '../../services/theme.service';
import { Observable } from 'rxjs';
import { IUser } from 'src/interfaces/user.interface';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() public isSidebarCollapsed = true;
  @Output() public onToggleSidebarClick = new EventEmitter<void>();

  public readonly roleI18n = roleI18n;

  public user$: Observable<IUser>;
  public isDarkTheme$!: Observable<boolean>;

  public navMenuItems: INavItem[] = [];
  public currentDate = new Date();

  constructor(
    public authService: AuthService,
    private themeService: ThemeService
  ) {
    this.user$ = this.authService.user$.pipe(
      filter((user): user is IUser => !!user),
      tap(user => {
        this.navMenuItems = navItems.filter(item => item.visibility(user.role));
      })
    );
    this.isDarkTheme$ = this.themeService.isDarkTheme$;
  }

  public toggleSidebar(): void {
    this.onToggleSidebarClick.emit();
  }

  public logout(): void {
    this.authService.logout().subscribe();
  }
}
