import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Forms
import { ReactiveFormsModule } from '@angular/forms';

//Feature Modules
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './material/material.module';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { AuthService } from './shared/services/auth.service';
import { ErrorInterceptor } from './interceptors';
import { LoaderInterceptor } from './interceptors/loader/loader.interseptor.service';
import { ApiInterceptor } from './interceptors/api/api.interceptor';
import { JwtInterceptor } from './interceptors/jwt/jwt.interceptor';
import { TimezoneInterceptor } from './interceptors/timezone/timezone.interseptor.service';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import {
  MatLegacyDialogConfig as MatDialogConfig,
  MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/legacy-dialog';
import {
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatLegacySnackBarConfig as MatSnackBarConfig,
} from '@angular/material/legacy-snack-bar';
import { SnackBarModule } from './shared/modules/snack-bar/snack-bar.module';
import {
  FullscreenOverlayContainer,
  OverlayContainer,
} from '@angular/cdk/overlay';

registerLocaleData(localeRu);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SharedModule,
    MaterialModule,
    HttpClientModule,
    SnackBarModule,
  ],
  providers: [
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: 'ru',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => async () => {
        await authService.getUser().toPromise();
      },
      deps: [AuthService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimezoneInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useFactory: (): MatDialogConfig => {
        const defautlConfig = new MatDialogConfig();
        return {
          ...defautlConfig,
          autoFocus: false,
          panelClass: 'dialog',
          backdropClass: 'dialog-backdrop',
        };
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useFactory: (): MatSnackBarConfig => {
        const defautlConfig = new MatSnackBarConfig();
        return {
          ...defautlConfig,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 0,
        };
      },
    },
    {
      provide: OverlayContainer,
      useClass: FullscreenOverlayContainer,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
