<div class="notifications__header">
  <span class="notifications__title" i18n>Новые сообщения</span>
  <span class="notifications__button" (click)="deleteAllMessages()" i18n
    >Скрыть все</span
  >
</div>
<div class="group" *ngFor="let group of messageDataGroup">
  <span class="title-date">{{
    group.date | date: 'cccc, d MMMM yyyy' : '' : language
  }}</span>
  <ol>
    <li *ngFor="let message of group.list">
      <app-notification
        (onRedirect)="handleRedirect()"
        [message]="message"
      ></app-notification>
    </li>
  </ol>
</div>
