import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { IResponseBase } from 'src/interfaces/response.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Theme } from 'src/interfaces/user.interface';
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public isDarkTheme$ = new BehaviorSubject<boolean>(
    <Theme>localStorage.getItem('theme') === 'dark'
  );

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) {}

  public getTheme(): Observable<boolean> {
    const theme = <null | Theme>localStorage.getItem('theme');
    return !theme
      ? this.authService.getUser().pipe(
          map(user => user.theme === 'dark'),
          tap(theme => this.isDarkTheme$.next(theme))
        )
      : this.isDarkTheme$;
  }

  public setTheme(theme: Theme): Observable<IResponseBase> {
    localStorage.setItem('theme', theme);
    return this.http
      .patch<IResponseBase>('api/users/me', { theme })
      .pipe(tap(() => this.isDarkTheme$.next(theme === 'dark')));
  }
}
