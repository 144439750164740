<div
  class="sidebar"
  [ngClass]="!isSidebarCollapsed ? 'sidebar_open' : 'sidebar_closed'"
  [class.dark-theme]="isDarkTheme$ | async"
  *ngIf="user$ | async as user"
>
  <div
    class="sidebar__arrow arrow d-tablet-none"
    [class.arrow_active]="!isSidebarCollapsed"
    (click)="toggleSidebar()"
  ></div>
  <div class="sidebar__profile profile d-tablet-none">
    <img
      routerLink="/dashboard/user"
      [src]="user.avatarPaths.mini | avatar"
      class="profile__photo"
      alt="uploaded photo"
    />
    <div class="profile__description user">
      <ng-template #userFullNameTooltip>
        <span>{{ user.fullName }}</span>
      </ng-template>
      <span
        class="user-name"
        #userFullName
        [tooltip]="userFullNameTooltip"
        [tooltipDisabled]="
          !(userFullName.offsetWidth < userFullName.scrollWidth)
        "
      >
        {{ user.fullName }}
      </span>
      <span>{{ roleI18n[user.role] }}</span>
    </div>
  </div>
  <ul class="sidebar__navigation navigation">
    <li
      class="navigation__item"
      *ngFor="let item of navMenuItems"
      [class.navigation__item_contacts]="item.link.includes('contacts')"
      [class.navigation__item_logout]="!item.link"
      [routerLink]="item.link"
      [routerLinkActive]="item.activeClass"
      (click)="item.action && item.action(logout())"
      [attr.data-test]="item.datatest"
      [tooltip]="menuItemTooltip"
      tooltipDirection="right"
      [tooltipDisabled]="!isSidebarCollapsed"
    >
      <ng-template #menuItemTooltip>
        <p class="menu-tooltip">{{ item.title }}</p>
      </ng-template>
      <img
        class="navigation__icon"
        [src]="(isDarkTheme$ | async) ? item.darkThemeIconPath : item.iconPath"
        alt="Navigation icon"
      />
      <span class="navigation__text">{{ item.title }}</span>
    </li>
  </ul>
  <ng-template #logoutTooltip>
    <p class="menu-tooltip" i18n>Выход</p>
  </ng-template>
  <div
    class="sidebar__logout logout d-tablet-none"
    routerLink=""
    (click)="logout()"
    [tooltip]="logoutTooltip"
    tooltipDirection="right"
    [tooltipDisabled]="!isSidebarCollapsed"
  >
    <img class="logout__icon" src="/assets/icons/sidebar/logout-white.svg" />
    <span class="logout__text" i18n>Выход</span>
  </div>
  <div class="sidebar__company company d-none d-tablet-flex">
    <img
      class="company__icon"
      [src]="
        (isDarkTheme$ | async)
          ? 'assets/icons/ncode-logo-light.svg'
          : 'assets/icons/ncode-logo.svg'
      "
      alt="ncode-logo"
    />
    <span class="company__text"
      >&copy; N-CODE {{ currentDate | date: 'YYYY' }}</span
    >
  </div>
</div>
