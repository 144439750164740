export const week = 7;
export const minutesInHour = 60;
export const halfOfHourInMinutes = 30;
export const msInSeconds = 1000;
export const secondsInMinute = 60;
export const msInMinute = secondsInMinute * msInSeconds;
export const hoursInDay = 24;
export const msInDay = hoursInDay * minutesInHour * secondsInMinute * msInSeconds;
export const msInHour = minutesInHour * secondsInMinute * msInSeconds;
export const msInHalfHour = msInHour / 2;
export const changeLessonRestriction = week * msInDay;
