<ng-container *ngFor="let item of tabs">
	<button
		type="button"
		*ngIf="item.isShow ?? true"
		class="item"
		[class.active]="activeTab === item.key"
		[disabled]="item.disabled"
		(click)="onTabClick(item.key)"
	>
		{{ item.title }}
	</button>
</ng-container>
