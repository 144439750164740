import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ISelectData } from 'src/interfaces/filters.interface';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent {
  @Input() public formGroup!: UntypedFormGroup;
  @Input() public label = '';
  @Input() public placeholderText = '';
  @Input() public optionsList: ISelectData[] = [];
  @Input() public controlName!: string;
  @Input() public withResetButton = false;
  @Input() public disabled = false;
  @Input() public withChips = false;

  @Output() public onReset = new EventEmitter<string | number | Date>();

  public onClearClick(event: Event): void {
    event.stopPropagation();
    this.onReset.emit(this.formGroup.controls[this.controlName].value);
    this.formGroup.controls[this.controlName].reset();
  }

  public getChipText(chipsValue: string): string {
    return this.optionsList.find(item => item.value === chipsValue)?.text || '';
  }

  public removeChip(chipValue: string): void {
    const value = this.formGroup.get(this.controlName)?.value;
    const newValue = value?.filter((item: string) => item !== chipValue);
    this.formGroup.get(this.controlName)?.setValue(newValue);
    this.formGroup.get(this.controlName)?.markAllAsTouched();
    this.formGroup.get(this.controlName)?.markAsDirty();
  }

  public get isRequired(): boolean {
    return !!this.formGroup
      .get(this.controlName)
      ?.hasValidator(Validators.required);
  }

  // TODO To think: Implement ControlValueAccessor through NgControl or smth if this component will be used without FormGroup
}
