export const isEqual = <T>(val1: T, val2: T): boolean => {
  if (val1 === val2) {
    return true;
  }

  if (Array.isArray(val1) && Array.isArray(val2)) {
    if (val1.length !== val2.length) {
      return false;
    }

    return val1.every((el, index) => el === val2[index]);
  }

  if (val1 && val2 && typeof val1 === 'object' && typeof val2 === 'object') {
    const keys1 = Object.keys(val1);
    const keys2 = Object.keys(val2);

    if(keys1.length !== keys2.length) {
        return false
    }

    return keys1.every((key) => val1[key as keyof T] === val2[key as keyof T]);
  }

  if(Number.isNaN(val1) && Number.isNaN(val2)){
    return true
  }

  return false;
};
