<div class="month">
    <span class="month__arrow month__arrow-left" (click)="setNewDate('previous')" data-test="month__arrow-left"></span>
    <span class="month__text" [class.month__text_clickable]="withCalendar" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="openCalendar()">
        {{ type === 'month' ? (date | date: 'LLLL YYYY' : "" : language | titlecase) : (date | date: 'EEEE, d MMMM YYYY' : "" : language | lowercase) }}
    </span>
    <span class="month__arrow month__arrow-right" (click)="setNewDate('next')" data-test="month__arrow-right"></span>
</div>
<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isCalendarOpen"
    [cdkConnectedOverlayWidth]="'330px'"
    [cdkConnectedOverlayPanelClass]="'calendarPanel'"
    cdkConnectedOverlayBackdropClass="calendarBackdrop"
    [cdkConnectedOverlayOffsetX]="-65"
    [cdkConnectedOverlayHasBackdrop]="true"
    (backdropClick)="closeCaledar()"
>
    <app-date-picker
        [date]="date"
        (onChangeDate)="onSelectedDate($event)"
    >
    </app-date-picker>
</ng-template>