<modal size="sm">
  <modal-title>
    <div>{{ title }}</div>
    <span *ngIf="role === 'student'" class="tooltip">
      <img
        tooltipDirection="right"
        [tooltip]="timeTooltip"
        src="assets/icons/exclude.svg"
        alt="tooltip"
        class="tooltip__img"
      />
    </span>
    <ng-template #timeTooltip>
      <span class="tooltip-title" i18n>Доступное время:</span>
      <br />
      <span class="tooltip-available-time">{{ availableTime }}</span>
    </ng-template>
  </modal-title>
  <modal-body>
    <app-actions-info
      [date]="defaultLessonTimeRange"
      [status]="lessonStatusI18n[lessonStatus]"
      [hasStatus]="isEditing"
      [hasTime]="isEditing"
      [statusClass]="lessonStatus"
    >
    </app-actions-info>
    <div class="changing">
      <div class="changing__user-name" *ngIf="!isStudentSelectorVisible">
        {{ userFullName }}
      </div>
      <div
        class="changing__students-list"
        *ngIf="isStudentSelectorVisible"
        [formGroup]="dialogForm"
      >
        <mat-form-field class="input">
          <mat-select
            formControlName="studentId"
            [placeholder]="placeholders.selectStudent"
          >
            <mat-option *ngFor="let student of students" [value]="student.id">
              {{ student.firstName }} {{ student.lastName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="studentControlHasError" i18n
            >Обязательное поле. Выберите студента</mat-error
          >
        </mat-form-field>
      </div>
      <div class="changing__title d-md-none">{{ formTitle }}</div>
      <form class="changing__inputs inputs" [formGroup]="dialogForm">
        <span class="d-none d-md-block inputs__title" i18n>Начало урока</span>
        <mat-form-field class="inputs__item input">
          <mat-select
            formControlName="startsAt"
            (selectionChange)="onStartLessonSelected()"
          >
            <mat-option *ngFor="let item of startLessonTime" [value]="item">
              {{ item | date: 'HH:mm' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span class="d-none d-md-block inputs__title" i18n
          >Окончание урока</span
        >
        <mat-form-field class="inputs__item input">
          <mat-select formControlName="endsAt">
            <mat-option *ngFor="let item of endLessonTime" [value]="item">
              {{ item | date: 'HH:mm' }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="endControlHasError" i18n
            >Выберите время окончания</mat-error
          >
        </mat-form-field>
      </form>
      <div class="checkbox" [formGroup]="dialogForm">
        <mat-checkbox
          color="primary"
          formControlName="isRepeat"
          [disabled]="isRepeatCheckboxDisabled"
        >
          <mat-label
            class="checkbox-label"
            [class.checkbox-label_disabled]="isRepeatCheckboxDisabled"
            i18n
            >Повторять еженедельно</mat-label
          >
        </mat-checkbox>
      </div>
    </div>

    <div
      class="comment"
      *ngIf="isTutor || (!isTutor && isEditing)"
      [formGroup]="dialogForm"
    >
      <div class="comment__title" i18n>Комментарий к уроку</div>
      <div class="comment__textarea-container">
        <textarea
          #textArea
          (input)="handleTextareaInput()"
          class="comment__textarea"
          i18n-placeholder
          placeholder="Комментарий:"
          formControlName="comment"
          maxlength="2500"
        ></textarea>
      </div>
    </div>
  </modal-body>
  <modal-footer>
    <app-action-buttons
      (buttonClick)="onButtonClick($event)"
      [isConfirmButtonDisable]="
        endControlHasError ||
        studentControlHasError ||
        (isEditing && !(isDirty$ | async))
      "
    >
    </app-action-buttons>
  </modal-footer>
</modal>
