import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';

import {
  IMessage,
  INotificationsResponse,
} from 'src/interfaces/messages.interface';
import { AuthService } from '../../../services/auth.service';
import { SocketIOService } from '../../../services/socketIO.service';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  DELETE_NOTIFICATION,
  NOTIFICATION_BASE,
} from '../constants/endpoints.constant';
import { NOTIFICATION_AUDIO } from '../constants/media-path.contants';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public messages$ = new BehaviorSubject<IMessage[]>([]);
  private audioNotification = new Audio(NOTIFICATION_AUDIO);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private socketService: SocketIOService
  ) {}

  public initMessages(): Observable<[IMessage, IMessage[]]> {
    const socket$ = this.getSocket();
    const messages$ = this.getNotifications();

    return combineLatest([socket$, messages$]);
  }

  public canDeleteMessage(message: IMessage): boolean {
    if (this.authService.user?.role !== 'admin') {
      const conditionsForDeleting =
        message.type === 'negative balance' &&
        this.authService.user!.balance < 0;
      return !conditionsForDeleting;
    }
    return true;
  }

  public deleteMessage(message: IMessage): Observable<IMessage[]> {
    const url = DELETE_NOTIFICATION(message._id);

    return this.http.delete(url).pipe(switchMap(() => this.getNotifications()));
  }

  private getNotifications(): Observable<IMessage[]> {
    return this.http.get<INotificationsResponse>(NOTIFICATION_BASE).pipe(
      map(response => response.currentUserNotifications.reverse()),
      tap(messages => this.messages$.next(messages))
    );
  }

  private getSocket(): Observable<IMessage> {
    return this.socketService.listen('new-notification').pipe(
      tap(message => {
        this.audioNotification.play();
        this.messages$.next([message, ...this.messages$.value]);
      })
    );
  }
}
