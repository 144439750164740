import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMessage } from 'src/interfaces/messages.interface';
import { Router } from '@angular/router';
import { DEFAULT_ICON_PATH } from '../../constants/media-path.contants';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NotificationService } from 'src/app/shared/components/notifications/services/notification.service';
import { DestroyService } from 'src/app/shared/services/destroy.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  host: { class: 'message' },
  providers: [DestroyService],
})
export class NotificationComponent {
  @Input() public message!: IMessage;
  @Output() public onRedirect: EventEmitter<void> = new EventEmitter();

  public defaultNotificationIconPath = DEFAULT_ICON_PATH;

  constructor(
    public authService: AuthService,
    public notificationService: NotificationService,
    private router: Router,
    private destroy$: DestroyService
  ) {}

  public onMessageClick(message: IMessage): void {
    this.redirectTo(message.type);
  }

  public onConfirmClick(message: IMessage, event: Event): void {
    event.stopPropagation();
    this.notificationService
      .deleteMessage(message)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public canDeleteMessage(message: IMessage): boolean {
    return this.notificationService.canDeleteMessage(message);
  }

  public deleteMessage(message: IMessage, event?: Event): void {
    if (event) event.stopPropagation();
    this.notificationService
      .deleteMessage(message)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private redirectTo(type: string): void {
    this.onRedirect.emit();
    switch (type) {
      case 'negative balance':
        this.router.navigate(['/dashboard/payment']);
        break;
      case 'tutor assigned':
        this.router.navigate(['/dashboard/user']);
        break;
      case 'student assigned':
        this.router.navigate(['/dashboard/user']);
        break;
      case 'version update':
        this.router.navigate(['/dashboard/contacts'], {
          queryParams: { page: 'updates' },
        });
        break;
      case 'role verification':
        this.router.navigate(['/dashboard/admin']);
        break;
      case 'first lesson':
        if (this.authService.user?.role === 'admin') {
          this.router.navigate(['/dashboard/admin']);
          break;
        }
        this.router.navigate(['/dashboard/calendar']);
        break;
      case 'created payout':
      case 'payout deleted':
        this.router.navigate(['/dashboard/admin-payout']);
        break;
      case 'payout success':
        this.router.navigate(['/dashboard/tutor-report']);
        break;
      case 'payout fail':
        this.router.navigate(['/dashboard/tutor-report']);
        break;
      case 'tutor has been deleted for tutor':
        this.router.navigate(['/dashboard/user']);
        break;
      default:
        this.router.navigate(['/dashboard/calendar']);
        setTimeout(() => document.location.reload()); //because navigate is async function and need to wait this shit
        break;
    }
  }
}
