import { Tab } from 'src/interfaces/tab.interface';
import { UserRole } from 'src/interfaces/user.interface';

export enum UserTab {
    Profile = 'profile',
    List = 'list',
    Settings = 'settings',
    Education = 'education'
}

// TODO Move to other constants, change name (now it's use not only User component)
export const userTabs = (role: UserRole, page: 'current' | 'associated'): Tab<UserTab>[] => [
	{
		key: UserTab.Profile,
		title: $localize `Личные данные`,
	},
	{
		key: UserTab.List,
		title: role === 'student' ? $localize `Преподаватели` : $localize `Студенты`,
		isShow: role !== 'admin' && page === 'current',
	},
	{
		key: UserTab.Settings,
		title: $localize `Настройки`,
		isShow: page === 'current'
	},
	{
		key: UserTab.Education,
		title: $localize `Обучение`,
		isShow: page === 'associated'
	},
];
