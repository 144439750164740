import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-label-container',
  templateUrl: './label-container.component.html',
  styleUrls: ['./label-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelContainerComponent {
  @Input() labelText = '';
}
