<modal size="sm">
    <modal-title>{{ title }}</modal-title>
    <modal-body>
      <app-actions-info
        [date]="date"
        [hasTime]="false"
        [hideTime]="true"
        [isStatusVisible]="false"
      ></app-actions-info>
      <form class="inputs" [formGroup]="dialogForm">
        <app-label-container
          class="selects__item"
          [labelText]="placeholders.blockedStart"
        >
          <app-select
            [formGroup]="dialogForm"
            controlName="startsAt"
            [placeholderText]="placeholders.selectTime"
            [withResetButton]="false"
            [optionsList]="startTime"
          ></app-select>
        </app-label-container>
        <app-label-container
          class="selects__item"
          [labelText]="placeholders.blockedEnd"
        >
          <app-select
            [formGroup]="dialogForm"
            controlName="endsAt"
            [placeholderText]="placeholders.selectTime"
            [withResetButton]="false"
            [optionsList]="endTime"
          ></app-select>
        </app-label-container>
      </form>
    </modal-body>
    <modal-footer>
      <app-action-buttons
        (buttonClick)="onButtonClick($event)"
        [isConfirmButtonDisable]="endControlHasError"
      ></app-action-buttons>
    </modal-footer>
  </modal>
