import { IRange } from 'src/interfaces/calendar.interface';
import { deviceType } from '../dashboard/calendar/helpers/deviceDetector.helper';

export const numberOfDisplayedDays = (): number =>
  deviceType() === 'mobile' ? 1 : 7;
export const actionAvailableDays = 2;

export const calendarHoursRange: IRange<number> = {
  start: 0,
  end: 24,
};

export const maxLessonDurationRange: IRange<number> = {
  min: 30, // in BRD === 60 min
  max: 180,
};

export const maxLessonDurationDefault = 120;
export const breakDurationDefault = 30;
export const defaultLessonDuration = 60;

export const breakDurationRange: IRange<number> = {
  min: 0,
  max: 120,
};

export const availableTimeRange: IRange<number> = {
  min: 30,
  max: 0,
};
