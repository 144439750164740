import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { tap } from "rxjs/operators"

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const conditions = req.url.includes('api/auth/signup')
        || req.url.endsWith('/lessons')
        || req.url.includes('/lessons?')
    if (conditions) {
        const headerName = 'ncs-user-time-zone';
        const headerValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zoneReq = req.clone({ headers: req.headers.set(headerName, headerValue), });
        return next.handle(zoneReq);
    }
    return next.handle(req);
  }
}
