<header class="header" [class.header_dark]="isDarkTheme$ | async">
  <div class="hamburger" (click)="handleHamburgerClick()">
    <div
      class="hamburger__container"
      [class.hamburger__container_open]="!isSidebarCollapsed"
    >
      <div class="hamburger__line"></div>
    </div>
  </div>

  <h1 class="header__title">{{ headerTitle }}</h1>

  <div class="right-part">
    <a
      *ngIf="user.role !== 'admin' && meetingUrl"
      class="meet"
      [href]="meetingUrl"
      target="_blank"
    >
      <svg
        width="34"
        height="20"
        viewBox="0 0 34 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="&#208;&#178;&#208;&#184;&#208;&#180;&#208;&#181;&#208;&#190;&#208;&#178;&#209;&#129;&#209;&#130;&#209;&#128;&#208;&#181;&#209;&#135;&#208;&#176;"
          d="M22.3333 10V17.92C22.3333 18.0618 22.3057 18.2023 22.2521 18.3333C22.1985 18.4643 22.12 18.5834 22.0209 18.6837C21.9219 18.784 21.8043 18.8635 21.6749 18.9178C21.5454 18.9721 21.4067 19 21.2667 19H2.06667C1.92659 19 1.78788 18.9721 1.65847 18.9178C1.52906 18.8635 1.41147 18.784 1.31242 18.6837C1.21337 18.5834 1.1348 18.4643 1.08119 18.3333C1.02759 18.2023 1 18.0618 1 17.92V2.08C1 1.79357 1.11238 1.51886 1.31242 1.31632C1.51246 1.11379 1.78377 1 2.06667 1H21.2667C21.5496 1 21.8209 1.11379 22.0209 1.31632C22.221 1.51886 22.3333 1.79357 22.3333 2.08V10ZM22.3333 10L31.2507 2.476C31.4064 2.34464 31.5959 2.26094 31.797 2.23471C31.998 2.20847 32.2023 2.24078 32.3859 2.32785C32.5694 2.41493 32.7247 2.55316 32.8335 2.72636C32.9422 2.89956 33 3.10055 33 3.3058V16.6942C32.9997 16.8993 32.9416 17.1 32.8327 17.273C32.7238 17.4459 32.5686 17.5839 32.3851 17.6707C32.2016 17.7576 31.9974 17.7897 31.7965 17.7634C31.5956 17.7371 31.4063 17.6535 31.2507 17.5222L22.3333 10Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </a>
    <app-language-select />
    <app-theme-toggle
      [toggleOn]="(isDarkTheme$ | async) || false"
      (onChangeTheme)="onThemeChange($event)"
    />
    <app-notifications />
  </div>
</header>
