<svg
  [matMenuTriggerFor]="menu"
  width="36"
  height="36"
  viewBox="0 0 36 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6 18C6 24.6276 11.3724 30 18 30C24.6276 30 30 24.6276 30 18C30 11.3724 24.6276 6 18 6C11.3724 6 6 11.3724 6 18Z"
    stroke="#F1F2F4"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M19.707 6.0752C19.707 6.0752 26.3402 10.8392 26.3402 18.0754C26.3402 25.3116 19.707 30.0752 19.707 30.0752M17.295 30.0752C17.295 30.0752 10.662 25.3116 10.662 18.0754C10.662 10.8392 17.295 6.0752 17.295 6.0752M8.25 23.5026H28.7525M8.25 12.6481H28.7525"
    stroke="#F1F2F4"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<div class="language" [matMenuTriggerFor]="menu">{{ currentLanguage }}</div>
<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    *ngFor="let language of languageOptions"
    (click)="setLanguage(language.value)"
  >
    {{ language.text }}
  </button>
</mat-menu>
