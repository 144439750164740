import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from 'src/app/shared/services/destroy.service';

@Directive({
  selector: '[appOneToggleSelected]'
})
export class OneToggleSelectedDirective implements OnInit {
  @Input() firstToggle!: AbstractControl;
  @Input() secondToggle!: AbstractControl;

  constructor(private destroy$: DestroyService) { }

  ngOnInit(): void {
    this.firstToggle.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value && this.secondToggle.value) {
        this.secondToggle.setValue(false);
      }
    });

    this.secondToggle.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value && this.firstToggle.value) {
        this.firstToggle.setValue(false);
      }
    });
  }
}
