<mat-menu [hasBackdrop]="false">
    <ng-template matMenuContent let-contextMenuItems="menuItems">
        <div class="item" *ngFor="let item of contextMenuItems">
            <button class="mat-menu-item"
                [attr.data-test]="item.event"
                (click)="menuItemClick(item)">
                <span>{{ item.title }}</span>
            </button>
        </div>
    </ng-template>
</mat-menu>