import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';
import { SnackBarData } from '../interfaces/snack-bar-data.interface';
import { ConfirmSnackBarComponent } from '../components/confirm-snack-bar/confirm-snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private _snackBar: MatSnackBar) {}

  public confirm(message: string): MatSnackBarRef<unknown> {
    const data: SnackBarData = { message, type: 'confirm' };

    return this._snackBar.openFromComponent(ConfirmSnackBarComponent, {
      data,
      duration: 3000,
    });
  }

  public info(message: string): MatSnackBarRef<unknown> {
    const data: SnackBarData = { message, type: 'info' };

    return this._snackBar.openFromComponent(SnackBarComponent, { data });
  }

  public success(message: string): MatSnackBarRef<unknown> {
    const data: SnackBarData = { message, type: 'success' };

    return this._snackBar.openFromComponent(SnackBarComponent, { data });
  }

  public warn(message: string): MatSnackBarRef<unknown> {
    const data: SnackBarData = { message, type: 'warn' };

    return this._snackBar.openFromComponent(SnackBarComponent, { data });
  }

  public error(message: string): MatSnackBarRef<unknown> {
    const data: SnackBarData = { message, type: 'error' };

    return this._snackBar.openFromComponent(SnackBarComponent, { data });
  }
}
