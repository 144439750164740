import { Component, Input, Optional, Self } from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent {
  @Input() public formControl!: UntypedFormControl;
  @Input() public minRows = 5;
  @Input() public maxRows = 10;
  @Input() public resizeable = false;
  @Input() public label = '';
  @Input() public placeholder = '';
  @Input() public maxLength = '';

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = {
        writeValue(): void {},
        registerOnChange(): void {},
        registerOnTouched(): void {},
      };
    }
  }
}
