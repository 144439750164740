<mat-form-field class="input textarea">
  <mat-label *ngIf="label"> {{ label }}</mat-label>
  <textarea
    [formControl]="formControl"
    matInput
    [cdkTextareaAutosize]="resizeable"
    [cdkAutosizeMinRows]="minRows"
    [cdkAutosizeMaxRows]="maxRows"
    [placeholder]="placeholder"
    [maxlength]="maxLength"
  ></textarea>
  <mat-error><ng-content select="[error]"></ng-content></mat-error>
</mat-form-field>
