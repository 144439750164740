import { genderi18n } from '../i18n/gender.i18n';

export const ERROR = {
  required: $localize`Это поле обязательно для заполнения`,
  minlength: $localize`Пожалуйста, введите в поле значение от двух символов`,
  maxlength: $localize`Превышено максимально допустимое количество символов`,
  pattern: $localize`Некорректный формат вводимых данных`,
  shortlength: $localize`Слишком короткое значение поля`,
  incorrectNumber: $localize`Введите валидный номер телефона`,
  minLengthNumber: $localize`Минимальноe количество символов в поле - 10`,
  passwordPattern: $localize`Пароль должен содержать буквы латиницы верхнего и нижнего регистра и цифры`,
  moreAvailable: $localize`Введите доступную Вам сумму`,
  lessHundredSum: $localize`Сумма должна быть не менее 100 грн`,
};

export const LABELS = {
  gender: $localize`Пол`,
  male: genderi18n.male,
  female: genderi18n.female,
  status: $localize`Статус`,
  active: $localize`Активный`,
  inactive: $localize`Неактивный`,
  verification: $localize`Верификация`,
  verified: $localize`Верифицирован`,
  unverified: $localize`Неверифицирован`,
  students: $localize`Студенты`,
  tutorAssigned: $localize`Назначен преподаватель`,
  tutorNotAssigned: $localize`Не назначен преподаватель`,
  search: $localize`Поиск`,
  role: $localize`Роль`,
  tutors: $localize`Преподаватели`,
  operation: $localize`Операция`,
  price: $localize`Цена (грн.)`,
  tutorFee: $localize`Выплата преподователю (грн.)`,
  assignable: $localize`Может взять студентов`,
  ableAssignStudent: $localize`Может`,
  notAbleAssignStudent: $localize`Не может`,
  dateLabel: $localize`Дата`,
};
