import { IDecomposedDate } from 'src/interfaces/calendar.interface';

// TODO: Расширить использование после успешного тестирования
export class DatePeriod {
  public readonly start!: Date;
  public readonly end!: Date;
  private readonly currentDate = new Date();

  constructor(start?: Date | null, end?: Date | null | number) {
    if (!start && !end) {
      this.start = this.getFirstDayOfMonth(this.currentDate);
      this.end = this.getLastDayOfMonth(this.currentDate);
    } else if (start && end === null) {
      this.start = this.getDateWithZeroTime(start);
      this.end = this.getDateAtEndOfDay(start);
    } else if (start && !end) {
      this.start = this.getFirstDayOfMonth(start);
      this.end = this.getLastDayOfMonth(start);
    } else if (start && end instanceof Date) {
      this.start = this.getDateWithZeroTime(start);
      this.end = this.getDateAtEndOfDay(end);
    } else if (start instanceof Date && typeof end === 'number') {
      this.start = this.getDateWithZeroTime(start);
      this.end = this.getDateAfterNDays(start, end);
    }
  }

  private getDateAfterNDays(start: Date, days: number): Date {
    const decomposedDate = this.getDecomposedDate(start);
    const nextDate = new Date(
      decomposedDate.year,
      decomposedDate.month,
      decomposedDate.dayOfMonth + days - 1
    );
    return this.getDateAtEndOfDay(nextDate);
  }

  // Return date with 23:59
  private getDateAtEndOfDay(date: Date): Date {
    const decomposedDate = this.getDecomposedDate(date);
    const nextDay = new Date(
      decomposedDate.year,
      decomposedDate.month,
      decomposedDate.dayOfMonth + 1
    );
    return new Date(+nextDay - 1);
  }

  // Return date with 00:00
  private getDateWithZeroTime(date: Date): Date {
    const dateString = date.toDateString();
    return new Date(dateString);
  }

  private getFirstDayOfMonth(date: Date): Date {
    const { year, month } = this.getDecomposedDate(date);
    return new Date(year, month);
  }

  private getLastDayOfMonth(date: Date): Date {
    const { year, month } = this.getDecomposedDate(date);
    const firstDayOfNextMonth = new Date(year, month + 1);
    return new Date(+firstDayOfNextMonth - 1);
  }

  private getDecomposedDate(date: Date): IDecomposedDate {
    const year = date.getFullYear();
    const month = date.getMonth();
    const dayOfMonth = date.getDate();
    return { year, month, dayOfMonth };
  }
}
