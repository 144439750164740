import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FilterPanelComponent } from '../filter-panel/filter-panel.component';

@Component({
  selector: 'app-mobile-filters-window',
  templateUrl: './mobile-filters-window.component.html',
  styleUrls: ['./mobile-filters-window.component.scss']
})
export class MobileFiltersWindowComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: {panel: FilterPanelComponent, type: string},
  ) {}

  public get isDefaultConfig(): boolean {
    const defaultFiltersJson = JSON.stringify(this.dialogData.panel.defaultFilter);
    const dataGroupJson = JSON.stringify(this.dialogData.panel.filterDataGroup.getRawValue());
    return defaultFiltersJson === dataGroupJson;
  }
}
