import { Directive, Input, HostBinding } from '@angular/core';
import { StatusColor } from '../interfaces/status.interface';

@Directive({
	selector: '[eStatus]',
	host: { class: 'e-status' },
})
export class StatusDirective {
	@HostBinding('class') @Input('eStatus') public color!: StatusColor;
}
