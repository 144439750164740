import { ApplicationRef, Directive, ElementRef, EventEmitter, Input, Output, SimpleChanges, TemplateRef } from '@angular/core';
import tippy, { sticky, Instance, Props } from 'tippy.js';
@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  @Input() public tooltip!: TemplateRef<unknown> | string;
  @Input() public tooltipDisabled = false;
  @Input() public tooltipDirection: 'top' | 'right' | 'bottom' | 'left' | 'top-start' | 'left-end' | 'bottom-start' = 'top';
  @Input() public tooltipDelay = 0;
  @Input() public tooltipTrigger = 'mouseenter focus';
  @Input() public maxWidth = 340;
  @Output() public onTooltipClick: EventEmitter<void> = new EventEmitter();

  private inst?: Instance;

  constructor(private readonly applicationRef: ApplicationRef, private readonly elementRef: ElementRef) {}


  public ngOnChanges({ tooltip, tooltipDisabled, tooltipDirection }: SimpleChanges): void {
		if (!this.inst) {
			return;
		}

		if (tooltipDisabled) {
			this.toggleDisable(this.tooltipDisabled);
		}

		if (tooltipDirection) {
			this.inst.setProps({ placement: this.tooltipDirection });
		}

		if (tooltip) {
			this.inst.setContent(this.getTooltipContent(this.tooltip));
		}
	}

  public ngAfterViewInit(): void {
		this.inst = this.createTippy();
		this.toggleDisable(this.tooltipDisabled);
	}

	public ngOnDestroy(): void {
		this.inst?.destroy();
	}

	private toggleDisable(disable: boolean): void {
		const method = disable ? 'disable' : 'enable';
		this.inst?.[method]();
	}

	private createTippy(): Instance {
		return tippy(this.elementRef.nativeElement as HTMLElement, {
			arrow: false,
			zIndex: 99,
			maxWidth: this.maxWidth,
			sticky: true,
			allowHTML: true,
			interactive: true,
			theme: 'light-border', // @see src/app/modules/tooltip/scss/_tooltip.scss
			animation: 'scale-subtle', // @see src/app/modules/tooltip/scss/_tooltip.scss
			placement: this.tooltipDirection,
			content: this.getTooltipContent(this.tooltip),
			plugins: [sticky],
			delay: this.tooltipDelay,
			trigger: this.tooltipTrigger,
			onShown: this.onShown(),
		});
	}

	private getTooltipContent(tooltip: TemplateRef<unknown> | string): HTMLElement | string {
		if (!(tooltip instanceof TemplateRef)) {
			return tooltip;
		}

		const content = document.createElement('div');
		const templateView = tooltip.createEmbeddedView({});
		this.applicationRef.attachView(templateView);

		content.append(...templateView.rootNodes);

		return content;
	}

	private onShown(): (instance: Instance<Props>) => void {
		return (instance: Instance<Props>) => {
			instance.popper.addEventListener('click', this.handleClick.bind(this))
		}
	  }

	private handleClick(event: Event): void {
		event.stopPropagation();
		this.onTooltipClick.next()
	}
}
