<div (click)="toggleClick()" class="toggle">
  <div [@toggleTrigger]="toggleOn ? 'on' : 'off'" class="toggle__icon">
    <svg
      *ngIf="toggleOn"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7.61429C0.99892 9.27982 1.56118 10.8968 2.5954 12.2023C3.62961 13.5078 5.07498 14.4252 6.69656 14.8052C8.31814 15.1853 10.0206 15.0058 11.5272 14.2958C13.0339 13.5858 14.2561 12.3871 14.9952 10.8946C8.38091 10.8946 5.10059 7.61351 5.10059 1C3.86889 1.61118 2.83237 2.55423 2.10786 3.72287C1.38335 4.89151 0.999649 6.23928 1 7.61429Z"
        fill="white"
        stroke="white"
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      *ngIf="!toggleOn"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11.8182C9.01264 11.8182 9.98382 11.4159 10.6999 10.6999C11.4159 9.98382 11.8182 9.01264 11.8182 8C11.8182 6.98735 11.4159 6.01619 10.6999 5.30014C9.98382 4.58409 9.01264 4.18182 8 4.18182C6.98735 4.18182 6.01619 4.58409 5.30014 5.30014C4.58409 6.01619 4.18182 6.98735 4.18182 8C4.18182 9.01264 4.58409 9.98382 5.30014 10.6999C6.01619 11.4159 6.98735 11.8182 8 11.8182Z"
        fill="white"
      />
      <path
        d="M14.3636 8H15M8 1.63636V1M8 15V14.3636M13.0909 13.0909L12.4545 12.4545M13.0909 2.90909L12.4545 3.54545M2.90909 13.0909L3.54545 12.4545M2.90909 2.90909L3.54545 3.54545M1 8H1.63636M8 11.8182C9.01264 11.8182 9.98382 11.4159 10.6999 10.6999C11.4159 9.98382 11.8182 9.01264 11.8182 8C11.8182 6.98735 11.4159 6.01619 10.6999 5.30014C9.98382 4.58409 9.01264 4.18182 8 4.18182C6.98735 4.18182 6.01619 4.58409 5.30014 5.30014C4.58409 6.01619 4.18182 6.98735 4.18182 8C4.18182 9.01264 4.58409 9.98382 5.30014 10.6999C6.01619 11.4159 6.98735 11.8182 8 11.8182Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</div>
