import { LessonStatus } from 'src/interfaces/lesson.interface';
import { StatusData } from '../shared/modules/status/interfaces/status.interface';

export const lessonStatusI18n: Record<LessonStatus, string> = {
	[LessonStatus.Approved]: $localize `Подтверждённый`,
	[LessonStatus.Canceled]: $localize `Отмененный`,
	[LessonStatus.Completed]: $localize `Завершенный`,
	[LessonStatus.Booked]: $localize `Забронированный`,
	[LessonStatus.PendingPayment]: $localize `Ожидание платежа`,
	[LessonStatus.FreeCompleted]: $localize `Завершенный`,
};

export const lessonStatusData: StatusData<LessonStatus> = {
	[LessonStatus.Approved]: { color: 'success', text: lessonStatusI18n[LessonStatus.Approved] },
	[LessonStatus.Canceled]: { color: 'danger-light', text: lessonStatusI18n[LessonStatus.Canceled] },
	[LessonStatus.Completed]: { color: 'disabled', text: lessonStatusI18n[LessonStatus.Completed] },
	[LessonStatus.Booked]: { color: 'frozen', text: lessonStatusI18n[LessonStatus.Booked] },
	[LessonStatus.PendingPayment]: { color: 'danger', text: lessonStatusI18n[LessonStatus.PendingPayment] },
	[LessonStatus.FreeCompleted]: { color: 'disabled', text: lessonStatusI18n[LessonStatus.FreeCompleted] },
};
