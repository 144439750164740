<div class="card" [class.dark-theme]="isDarkTheme$ | async">
  <div class="card__header">
    <img class="card__icon" [src]="logoUrl" />
    <ng-container
      *ngTemplateOutlet="cardItem; context: { item: data.header }"
    ></ng-container>
  </div>
  <div class="card__status" [ngClass]="'status_' + statusClass">
    <ng-container
      *ngTemplateOutlet="cardItem; context: { item: data.status }"
    ></ng-container>
  </div>
  <div class="card__data">
    <ng-container
      *ngTemplateOutlet="cardItem; context: { item: data.date }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="cardItem; context: { item: data.time }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="cardItem; context: { item: data.cost }"
    ></ng-container>
  </div>
  <span
    *ngIf="withContextMenu"
    class="card__menu"
    (click)="handleClick($event)"
  >
    <span class="menu"></span>
  </span>
</div>
<ng-template #cardItem let-item="item">
  <div class="card__item">
    <span class="card__title">{{ item.title + ':' }}</span>
    <span class="card__text">{{ item.text }}</span>
  </div>
</ng-template>
